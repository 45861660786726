<template>
  <div class="home">
    <!-- ナビメニュー -->
    <v-navigation-drawer app clipped>
      <patrolReportNavigationMenu
        v-on:patrolReport="moveToPatrolReport"
        v-on:moneyCheck="moveToMoneyCheck"
        v-on:stockCheck="moveToStockCheck"
      >
      </patrolReportNavigationMenu>
    </v-navigation-drawer>
    <p>金銭点検確認表</p>
    <v-row>
      <v-col align="right"><v-btn @click.once="back">戻る</v-btn></v-col>
    </v-row>
    <v-container cols="12">
      <div v-if="isLoading" align="center">
        <v-progress-circular
          :size="250"
          color="primary"
          indeterminate
          align="center"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-form ref="form" @submit.prevent>
          <v-row>
            <v-col cols="6" sm="6" md="4" lg="4">
              <v-text-field
                label="日付"
                v-model="data.Recodes.FID11340.value"
                :disabled="data.Recodes.FID11340.disabled || allDisabled11679"
                background-color="white"
                id="FID11340"
                class="textfield11340"
                outlined
                readonly
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <div v-show="false">
              {{ calc11931sp(data.Recodes.FID11931, data.Recodes) }}
            </div>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="4" lg="4">
              <v-text-field
                label="SSコード"
                v-model="data.Recodes.FID11341.value"
                :disabled="data.Recodes.FID11341.disabled || allDisabled11679"
                background-color="white"
                id="FID11341"
                class="textfield11341"
                outlined
                readonly
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4">
              <v-text-field
                label="SS名"
                v-model="data.Recodes.FID11342.value"
                :disabled="data.Recodes.FID11342.disabled || allDisabled11679"
                background-color="white"
                id="FID11342"
                class="textfield11342"
                outlined
                readonly
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4">
              <v-text-field
                label="責任者名"
                v-model="data.Recodes.FID11343.value"
                :disabled="data.Recodes.FID11343.disabled || allDisabled11679"
                background-color="white"
                id="FID11343"
                class="textfield11343"
                outlined
                readonly
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <patrolreportCreateSpecial
                label=""
                :disabled="allDisabled11679"
                background-color="white"
                id="FID11862"
                class=""
                :data="data"
                :pageID="1655"
                hide-details="auto"
                ref="patrolreportCreateSpecial"
              >
              </patrolreportCreateSpecial>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-divider
                label=""
                :disabled="allDisabled11679"
                background-color="white"
                id="FID11933"
                class="divider11933"
                :style="'border-bottom-width: 5px !important;'"
                color="black"
                hide-details="auto"
              >
              </v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="6"
              sm="6"
              md="3"
              lg="3"
              class="text-right align-self-center"
            >
              <div
                label=""
                :disabled="allDisabled11679"
                background-color="white"
                id="FID11344"
                class="plainText11344 subtitle-1"
                hide-details="auto"
              >
                点検時刻
              </div>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-btn
                label="時刻記録"
                :disabled="data.Recodes.FID11345.disabled || allDisabled11679"
                background-color="white"
                id="FID11345"
                class="button11345"
                @click="onBtn11345"
                color="yellow lighten-2"
                block
                style="font-size: 20px; letter-spacing: 10px"
                height="50"
                hide-details="auto"
              >
                時刻記録</v-btn
              >
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6">
              <v-text-field
                label="点検時刻"
                v-model="watchFromID11346"
                :disabled="data.Recodes.FID11346.disabled || allDisabled11679"
                background-color="white"
                id="FID11346"
                class="textfield11346"
                outlined
                readonly
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-divider
                label=""
                :disabled="allDisabled11679"
                background-color="white"
                id="FID11347"
                class="divider11347"
                :style="'border-bottom-width: 5px !important;'"
                color="black"
                hide-details="auto"
              >
              </v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="6"
              sm="6"
              md="6"
              lg="6"
              class="text-right align-self-center"
            >
              <div
                label=""
                :disabled="allDisabled11679"
                background-color="white"
                id="FID11348"
                class="plainText11348 subtitle-1"
                hide-details="auto"
              >
                点検実施者
              </div>
            </v-col>
            <v-col cols="6" sm="6" md="6" lg="6">
              <v-text-field
                label="※GM（巡回者）参照"
                v-model="data.Recodes.FID11349.value"
                disabled
                background-color="white"
                id="FID11349"
                class="userInfo11349"
                readonly
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-divider
                label=""
                :disabled="allDisabled11679"
                background-color="white"
                id="FID11855"
                class="divider11855"
                :style="'border-bottom-width: 5px !important;'"
                color="black"
                hide-details="auto"
              >
              </v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="0">
              <v-autocomplete
                label="金種内訳項目"
                v-model="inspectionItem"
                :rules="[rules.required]"
                background-color="white"
                outlined
                no-data-text="データが見つかりません"
                :items="inspectionItems"
                item-text="label"
                item-value="value"
                hide-details="auto"
                @change="handleInspectionItemChange"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <template v-if="isShowInspection1">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label=""
                  v-model="data.Recodes.FID11351.value"
                  :disabled="allDisabled11679"
                  :background-color="compClass11351"
                  id="FID11351"
                  class="textfield11351"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6">
                <div
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID12256"
                  class=""
                  hide-details="auto"
                ></div>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11412.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="10,000円枚数"
                        v-model="data.Recodes.FID11412.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11412"
                        class="textfield11412"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11412.modal"
                    title="10,000円枚数"
                    :value="data.Recodes.FID11412.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11412.modal = false),
                        $set(data.Recodes.FID11412, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6">
                <div
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11416"
                  class=""
                  hide-details="auto"
                ></div>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11417.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="5,000円枚数"
                        v-model="data.Recodes.FID11417.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11417"
                        class="textfield11417"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11417.modal"
                    title="5,000円枚数"
                    :value="data.Recodes.FID11417.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11417.modal = false),
                        $set(data.Recodes.FID11417, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6">
                <div
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11420"
                  class=""
                  hide-details="auto"
                ></div>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11421.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="2,000円枚数"
                        v-model="data.Recodes.FID11421.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11421"
                        class="textfield11421"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11421.modal"
                    title="2,000円枚数"
                    :value="data.Recodes.FID11421.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11421.modal = false),
                        $set(data.Recodes.FID11421, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6">
                <div
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11423"
                  class=""
                  hide-details="auto"
                ></div>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11427.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="1,000円枚数"
                        v-model="data.Recodes.FID11427.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11427"
                        class="textfield11427"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11427.modal"
                    title="1,000円枚数"
                    :value="data.Recodes.FID11427.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11427.modal = false),
                        $set(data.Recodes.FID11427, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" sm="3" md="3" lg="3">
                <div
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11426"
                  class=""
                  hide-details="auto"
                ></div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11430.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="500円棒金"
                        v-model="data.Recodes.FID11430.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11430"
                        class="textfield11430"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11430.modal"
                    title="500円棒金"
                    :value="data.Recodes.FID11430.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11430.modal = false),
                        $set(data.Recodes.FID11430, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11431.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="500円枚数"
                        v-model="data.Recodes.FID11431.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11431"
                        class="textfield11431"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11431.modal"
                    title="500円枚数"
                    :value="data.Recodes.FID11431.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11431.modal = false),
                        $set(data.Recodes.FID11431, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <div v-show="false">{{ data.Recodes.FID11436.value }}</div>
              <div v-show="false">{{ multi11434 }}</div>
              <div v-show="false">{{ multi11435 }}</div>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11438.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="100円棒金"
                        v-model="data.Recodes.FID11438.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11438"
                        class="textfield11438"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11438.modal"
                    title="100円棒金"
                    :value="data.Recodes.FID11438.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11438.modal = false),
                        $set(data.Recodes.FID11438, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11439.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="100円枚数"
                        v-model="data.Recodes.FID11439.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11439"
                        class="textfield11439"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11439.modal"
                    title="100円枚数"
                    :value="data.Recodes.FID11439.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11439.modal = false),
                        $set(data.Recodes.FID11439, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <div v-show="false">{{ data.Recodes.FID11682.value }}</div>
              <div v-show="false">{{ multi11443 }}</div>
              <div v-show="false">{{ multi11444 }}</div>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11446.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="50円棒金"
                        v-model="data.Recodes.FID11446.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11446"
                        class="textfield11446"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11446.modal"
                    title="50円棒金"
                    :value="data.Recodes.FID11446.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11446.modal = false),
                        $set(data.Recodes.FID11446, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11447.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="50円枚数"
                        v-model="data.Recodes.FID11447.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11447"
                        class="textfield11447"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11447.modal"
                    title="50円枚数"
                    :value="data.Recodes.FID11447.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11447.modal = false),
                        $set(data.Recodes.FID11447, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <div v-show="false">{{ data.Recodes.FID11683.value }}</div>
              <div v-show="false">{{ multi11451 }}</div>
              <div v-show="false">{{ multi11452 }}</div>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11454.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="10円棒金"
                        v-model="data.Recodes.FID11454.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11454"
                        class="textfield11454"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11454.modal"
                    title="10円棒金"
                    :value="data.Recodes.FID11454.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11454.modal = false),
                        $set(data.Recodes.FID11454, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11455.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="10円枚数"
                        v-model="data.Recodes.FID11455.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11455"
                        class="textfield11455"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11455.modal"
                    title="10円枚数"
                    :value="data.Recodes.FID11455.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11455.modal = false),
                        $set(data.Recodes.FID11455, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <div v-show="false">{{ data.Recodes.FID11684.value }}</div>
              <div v-show="false">{{ multi11459 }}</div>
              <div v-show="false">{{ multi11460 }}</div>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11462.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="5円棒金"
                        v-model="data.Recodes.FID11462.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11462"
                        class="textfield11462"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11462.modal"
                    title="5円棒金"
                    :value="data.Recodes.FID11462.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11462.modal = false),
                        $set(data.Recodes.FID11462, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11463.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="5円枚数"
                        v-model="data.Recodes.FID11463.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11463"
                        class="textfield11463"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11463.modal"
                    title="5円枚数"
                    :value="data.Recodes.FID11463.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11463.modal = false),
                        $set(data.Recodes.FID11463, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <div v-show="false">{{ data.Recodes.FID11685.value }}</div>
              <div v-show="false">{{ multi11467 }}</div>
              <div v-show="false">{{ multi11468 }}</div>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11470.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="1円棒金"
                        v-model="data.Recodes.FID11470.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11470"
                        class="textfield11470"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11470.modal"
                    title="1円棒金"
                    :value="data.Recodes.FID11470.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11470.modal = false),
                        $set(data.Recodes.FID11470, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11471.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="1円枚数"
                        v-model="data.Recodes.FID11471.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11471"
                        class="textfield11471"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11471.modal"
                    title="1円枚数"
                    :value="data.Recodes.FID11471.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11471.modal = false),
                        $set(data.Recodes.FID11471, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <div
                  label=""
                  background-color="white"
                  class=""
                  hide-details="auto"
                ></div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label="10,000円合計"
                  v-model="multi11413"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11413"
                  class="textfield11413"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label="5,000円合計"
                  v-model="multi11418"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11418"
                  class="textfield11418"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label="2,000円合計"
                  v-model="multi11422"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11422"
                  class="textfield11422"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label="1,000円合計"
                  v-model="multi11428"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11428"
                  class="textfield11428"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label="500円合計"
                  v-model="total11432"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11432"
                  class="textfield11432"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label="100円合計"
                  v-model="total11440"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11440"
                  class="textfield11440"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label="50円合計"
                  v-model="total11448"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11448"
                  class="textfield11448"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label="10円合計"
                  v-model="total11456"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11456"
                  class="textfield11456"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label="5円合計"
                  v-model="total11464"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11464"
                  class="textfield11464"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label="1円合計"
                  v-model="total11472"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11472"
                  class="textfield11472"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="1" sm="1" md="3" lg="3">
                <div
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11473"
                  class=""
                  hide-details="auto"
                ></div>
              </v-col>
              <div v-show="false">{{ data.Recodes.FID11686.value }}</div>
              <div v-show="false">{{ multi11475 }}</div>
              <div v-show="false">{{ multi11476 }}</div>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-divider
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11481"
                  class="divider11481"
                  :style="'border-bottom-width: 5px !important;'"
                  color="black"
                  hide-details="auto"
                >
                </v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="6"
                sm="6"
                md="6"
                lg="6"
                class="text-right align-self-center"
              >
                <div
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11482"
                  class="plainText11482 subtitle-1"
                  hide-details="auto"
                >
                  レジ合計
                </div>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-text-field
                  label="レジ合計"
                  v-model="total11483"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11483"
                  class="textfield11483"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </template>
          <template v-if="isShowInspection2">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label=""
                  v-model="data.Recodes.FID11503.value"
                  :disabled="allDisabled11679"
                  :background-color="compClass11503"
                  id="FID11503"
                  class="textfield11503"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6">
                <div
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11506"
                  class=""
                  hide-details="auto"
                ></div>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11507.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="10,000円枚数"
                        v-model="data.Recodes.FID11507.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11507"
                        class="textfield11507"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11507.modal"
                    title="10,000円枚数"
                    :value="data.Recodes.FID11507.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11507.modal = false),
                        $set(data.Recodes.FID11507, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6">
                <div
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11544"
                  class=""
                  hide-details="auto"
                ></div>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11545.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="5,000円枚数"
                        v-model="data.Recodes.FID11545.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11545"
                        class="textfield11545"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11545.modal"
                    title="5,000円枚数"
                    :value="data.Recodes.FID11545.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11545.modal = false),
                        $set(data.Recodes.FID11545, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6">
                <div
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11516"
                  class=""
                  hide-details="auto"
                ></div>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11549.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="2,000円枚数"
                        v-model="data.Recodes.FID11549.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11549"
                        class="textfield11549"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11549.modal"
                    title="2,000円枚数"
                    :value="data.Recodes.FID11549.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11549.modal = false),
                        $set(data.Recodes.FID11549, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6">
                <div
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11560"
                  class=""
                  hide-details="auto"
                ></div>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11553.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="1,000円枚数"
                        v-model="data.Recodes.FID11553.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11553"
                        class="textfield11553"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11553.modal"
                    title="1,000円枚数"
                    :value="data.Recodes.FID11553.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11553.modal = false),
                        $set(data.Recodes.FID11553, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" sm="3" md="3" lg="3">
                <div
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11509"
                  class=""
                  hide-details="auto"
                ></div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11512.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="500円棒金"
                        v-model="data.Recodes.FID11512.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11512"
                        class="textfield11512"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11512.modal"
                    title="500円棒金"
                    :value="data.Recodes.FID11512.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11512.modal = false),
                        $set(data.Recodes.FID11512, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11513.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="500円枚数"
                        v-model="data.Recodes.FID11513.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11513"
                        class="textfield11513"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11513.modal"
                    title="500円枚数"
                    :value="data.Recodes.FID11513.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11513.modal = false),
                        $set(data.Recodes.FID11513, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <div v-show="false">{{ data.Recodes.FID11687.value }}</div>
              <div v-show="false">{{ multi11517 }}</div>
              <div v-show="false">{{ multi11518 }}</div>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11556.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="100円棒金"
                        v-model="data.Recodes.FID11556.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11556"
                        class="textfield11556"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11556.modal"
                    title="100円棒金"
                    :value="data.Recodes.FID11556.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11556.modal = false),
                        $set(data.Recodes.FID11556, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11557.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="100円枚数"
                        v-model="data.Recodes.FID11557.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11557"
                        class="textfield11557"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11557.modal"
                    title="100円枚数"
                    :value="data.Recodes.FID11557.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11557.modal = false),
                        $set(data.Recodes.FID11557, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <div v-show="false">{{ data.Recodes.FID11688.value }}</div>
              <div v-show="false">{{ multi11561 }}</div>
              <div v-show="false">{{ multi11562 }}</div>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11571.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="50円棒金"
                        v-model="data.Recodes.FID11571.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11571"
                        class="textfield11571"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11571.modal"
                    title="50円棒金"
                    :value="data.Recodes.FID11571.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11571.modal = false),
                        $set(data.Recodes.FID11571, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11572.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="50円枚数"
                        v-model="data.Recodes.FID11572.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11572"
                        class="textfield11572"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11572.modal"
                    title="50円枚数"
                    :value="data.Recodes.FID11572.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11572.modal = false),
                        $set(data.Recodes.FID11572, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <div v-show="false">{{ data.Recodes.FID11689.value }}</div>
              <div v-show="false">{{ multi11575 }}</div>
              <div v-show="false">{{ multi11576 }}</div>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11577.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="10円棒金"
                        v-model="data.Recodes.FID11577.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11577"
                        class="textfield11577"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11577.modal"
                    title="10円棒金"
                    :value="data.Recodes.FID11577.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11577.modal = false),
                        $set(data.Recodes.FID11577, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11578.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="10円枚数"
                        v-model="data.Recodes.FID11578.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11578"
                        class="textfield11578"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11578.modal"
                    title="10円枚数"
                    :value="data.Recodes.FID11578.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11578.modal = false),
                        $set(data.Recodes.FID11578, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <div v-show="false">{{ data.Recodes.FID11690.value }}</div>
              <div v-show="false">{{ multi11581 }}</div>
              <div v-show="false">{{ multi11582 }}</div>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11583.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="5円棒金"
                        v-model="data.Recodes.FID11583.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11583"
                        class="textfield11583"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11583.modal"
                    title="5円棒金"
                    :value="data.Recodes.FID11583.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11583.modal = false),
                        $set(data.Recodes.FID11583, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11584.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="5円枚数"
                        v-model="data.Recodes.FID11584.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11584"
                        class="textfield11584"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11584.modal"
                    title="5円枚数"
                    :value="data.Recodes.FID11584.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11584.modal = false),
                        $set(data.Recodes.FID11584, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <div v-show="false">{{ data.Recodes.FID11691.value }}</div>
              <div v-show="false">{{ multi11587 }}</div>
              <div v-show="false">{{ multi11588 }}</div>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11589.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="1円棒金"
                        v-model="data.Recodes.FID11589.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11589"
                        class="textfield11589"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11589.modal"
                    title="1円棒金"
                    :value="data.Recodes.FID11589.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11589.modal = false),
                        $set(data.Recodes.FID11589, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11590.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="1円枚数"
                        v-model="data.Recodes.FID11590.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11590"
                        class="textfield11590"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11590.modal"
                    title="1円枚数"
                    :value="data.Recodes.FID11590.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11590.modal = false),
                        $set(data.Recodes.FID11590, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <div
                  label=""
                  background-color="white"
                  class=""
                  hide-details="auto"
                ></div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label="10,000円合計"
                  v-model="multi11508"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11508"
                  class="textfield11508"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label="5,000円合計"
                  v-model="multi11546"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11546"
                  class="textfield11546"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label="2,000円合計"
                  v-model="multi11550"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11550"
                  class="textfield11550"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label="1,000円合計"
                  v-model="multi11554"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11554"
                  class="textfield11554"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label="500円合計"
                  v-model="total11514"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11514"
                  class="textfield11514"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label="100円合計"
                  v-model="total11558"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11558"
                  class="textfield11558"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label="50円合計"
                  v-model="total11573"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11573"
                  class="textfield11573"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label="10円合計"
                  v-model="total11579"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11579"
                  class="textfield11579"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label="5円合計"
                  v-model="total11585"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11585"
                  class="textfield11585"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label="1円合計"
                  v-model="total11591"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11591"
                  class="textfield11591"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="1" sm="1" md="3" lg="3">
                <div
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11570"
                  class=""
                  hide-details="auto"
                ></div>
              </v-col>
              <div v-show="false">{{ data.Recodes.FID11692.value }}</div>
              <div v-show="false">{{ multi11593 }}</div>
              <div v-show="false">{{ multi11594 }}</div>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-divider
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11519"
                  class="divider11519"
                  :style="'border-bottom-width: 5px !important;'"
                  color="black"
                  hide-details="auto"
                >
                </v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="6"
                sm="6"
                md="6"
                lg="6"
                class="text-right align-self-center"
              >
                <div
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11520"
                  class="plainText11520 subtitle-1"
                  hide-details="auto"
                >
                  金庫合計
                </div>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-text-field
                  label="金庫合計"
                  v-model="total11521"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11521"
                  class="textfield11521"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </template>
          <template v-if="isShowInspection3">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label=""
                  v-model="data.Recodes.FID11504.value"
                  :disabled="allDisabled11679"
                  :background-color="compClass11504"
                  id="FID11504"
                  class="textfield11504"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6">
                <div
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11523"
                  class=""
                  hide-details="auto"
                ></div>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11524.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="10,000円枚数"
                        v-model="data.Recodes.FID11524.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11524"
                        class="textfield11524"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11524.modal"
                    title="10,000円枚数"
                    :value="data.Recodes.FID11524.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11524.modal = false),
                        $set(data.Recodes.FID11524, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6">
                <div
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11600"
                  class=""
                  hide-details="auto"
                ></div>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11601.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="5,000円枚数"
                        v-model="data.Recodes.FID11601.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11601"
                        class="textfield11601"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11601.modal"
                    title="5,000円枚数"
                    :value="data.Recodes.FID11601.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11601.modal = false),
                        $set(data.Recodes.FID11601, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6">
                <div
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11604"
                  class=""
                  hide-details="auto"
                ></div>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11605.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="2,000円枚数"
                        v-model="data.Recodes.FID11605.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11605"
                        class="textfield11605"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11605.modal"
                    title="2,000円枚数"
                    :value="data.Recodes.FID11605.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11605.modal = false),
                        $set(data.Recodes.FID11605, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6">
                <div
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11608"
                  class=""
                  hide-details="auto"
                ></div>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11609.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="1,000円枚数"
                        v-model="data.Recodes.FID11609.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11609"
                        class="textfield11609"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11609.modal"
                    title="1,000円枚数"
                    :value="data.Recodes.FID11609.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11609.modal = false),
                        $set(data.Recodes.FID11609, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" sm="3" md="3" lg="3">
                <div
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11526"
                  class=""
                  hide-details="auto"
                ></div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11529.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="500円棒金"
                        v-model="data.Recodes.FID11529.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11529"
                        class="textfield11529"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11529.modal"
                    title="500円棒金"
                    :value="data.Recodes.FID11529.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11529.modal = false),
                        $set(data.Recodes.FID11529, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11530.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="500円枚数"
                        v-model="data.Recodes.FID11530.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11530"
                        class="textfield11530"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11530.modal"
                    title="500円枚数"
                    :value="data.Recodes.FID11530.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11530.modal = false),
                        $set(data.Recodes.FID11530, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <div v-show="false">{{ data.Recodes.FID11693.value }}</div>
              <div v-show="false">{{ multi11534 }}</div>
              <div v-show="false">{{ multi11535 }}</div>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11613.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="100円棒金"
                        v-model="data.Recodes.FID11613.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11613"
                        class="textfield11613"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11613.modal"
                    title="100円棒金"
                    :value="data.Recodes.FID11613.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11613.modal = false),
                        $set(data.Recodes.FID11613, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11614.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="100円枚数"
                        v-model="data.Recodes.FID11614.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11614"
                        class="textfield11614"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11614.modal"
                    title="100円枚数"
                    :value="data.Recodes.FID11614.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11614.modal = false),
                        $set(data.Recodes.FID11614, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <div v-show="false">{{ data.Recodes.FID11694.value }}</div>
              <div v-show="false">{{ multi11633 }}</div>
              <div v-show="false">{{ multi11643 }}</div>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11617.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="50円棒金"
                        v-model="data.Recodes.FID11617.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11617"
                        class="textfield11617"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11617.modal"
                    title="50円棒金"
                    :value="data.Recodes.FID11617.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11617.modal = false),
                        $set(data.Recodes.FID11617, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11618.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="50円枚数"
                        v-model="data.Recodes.FID11618.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11618"
                        class="textfield11618"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11618.modal"
                    title="50円枚数"
                    :value="data.Recodes.FID11618.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11618.modal = false),
                        $set(data.Recodes.FID11618, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <div v-show="false">{{ data.Recodes.FID11695.value }}</div>
              <div v-show="false">{{ multi11635 }}</div>
              <div v-show="false">{{ multi11645 }}</div>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11621.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="10円棒金"
                        v-model="data.Recodes.FID11621.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11621"
                        class="textfield11621"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11621.modal"
                    title="10円棒金"
                    :value="data.Recodes.FID11621.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11621.modal = false),
                        $set(data.Recodes.FID11621, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11622.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="10円枚数"
                        v-model="data.Recodes.FID11622.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11622"
                        class="textfield11622"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11622.modal"
                    title="10円枚数"
                    :value="data.Recodes.FID11622.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11622.modal = false),
                        $set(data.Recodes.FID11622, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <div v-show="false">{{ data.Recodes.FID11696.value }}</div>
              <div v-show="false">{{ multi11637 }}</div>
              <div v-show="false">{{ multi11647 }}</div>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11625.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="5円棒金"
                        v-model="data.Recodes.FID11625.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11625"
                        class="textfield11625"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11625.modal"
                    title="5円棒金"
                    :value="data.Recodes.FID11625.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11625.modal = false),
                        $set(data.Recodes.FID11625, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11626.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="5円枚数"
                        v-model="data.Recodes.FID11626.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11626"
                        class="textfield11626"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11626.modal"
                    title="5円枚数"
                    :value="data.Recodes.FID11626.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11626.modal = false),
                        $set(data.Recodes.FID11626, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <div v-show="false">{{ data.Recodes.FID11697.value }}</div>
              <div v-show="false">{{ multi11639 }}</div>
              <div v-show="false">{{ multi11649 }}</div>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11629.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="1円棒金"
                        v-model="data.Recodes.FID11629.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11629"
                        class="textfield11629"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11629.modal"
                    title="1円棒金"
                    :value="data.Recodes.FID11629.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11629.modal = false),
                        $set(data.Recodes.FID11629, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11630.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="1円枚数"
                        v-model="data.Recodes.FID11630.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11630"
                        class="textfield11630"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11630.modal"
                    title="1円枚数"
                    :value="data.Recodes.FID11630.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11630.modal = false),
                        $set(data.Recodes.FID11630, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <div
                  label=""
                  background-color="white"
                  class=""
                  hide-details="auto"
                ></div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label="10,000円合計"
                  v-model="multi11525"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11525"
                  class="textfield11525"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label="5,000円合計"
                  v-model="multi11602"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11602"
                  class="textfield11602"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label="2,000円合計"
                  v-model="multi11606"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11606"
                  class="textfield11606"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label="1,000円合計"
                  v-model="multi11610"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11610"
                  class="textfield11610"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label="500円合計"
                  v-model="total11531"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11531"
                  class="textfield11531"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label="100円合計"
                  v-model="total11615"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11615"
                  class="textfield11615"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label="50円合計"
                  v-model="total11619"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11619"
                  class="textfield11619"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label="10円合計"
                  v-model="total11623"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11623"
                  class="textfield11623"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label="5円合計"
                  v-model="total11627"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11627"
                  class="textfield11627"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label="1円合計"
                  v-model="total11631"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11631"
                  class="textfield11631"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="1" sm="1" md="3" lg="3">
                <div
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11640"
                  class=""
                  hide-details="auto"
                ></div>
              </v-col>
              <div v-show="false">{{ data.Recodes.FID11698.value }}</div>
              <div v-show="false">{{ multi11641 }}</div>
              <div v-show="false">{{ multi11651 }}</div>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-divider
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11536"
                  class="divider11536"
                  :style="'border-bottom-width: 5px !important;'"
                  color="black"
                  hide-details="auto"
                >
                </v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="6"
                sm="6"
                md="6"
                lg="6"
                class="text-right align-self-center"
              >
                <div
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11537"
                  class="plainText11537 subtitle-1"
                  hide-details="auto"
                >
                  夜間金庫合計
                </div>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-text-field
                  label="夜間金庫合計"
                  v-model="total11538"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11538"
                  class="textfield11538"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </template>
          <template v-if="isShowInspection4">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-text-field
                  label=""
                  v-model="data.Recodes.FID11539.value"
                  :disabled="allDisabled11679"
                  :background-color="compClass11539"
                  id="FID11539"
                  class="textfield11539"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="6"
                sm="6"
                md="6"
                lg="6"
                class="text-right align-self-center"
              >
                <div
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11540"
                  class="plainText11540 subtitle-1"
                  hide-details="auto"
                >
                  aレジ合計
                </div>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-text-field
                  label="aレジ合計"
                  v-model="watchFromID11541"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11541"
                  class="textfield11541"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="6"
                sm="6"
                md="6"
                lg="6"
                class="text-right align-self-center"
              >
                <div
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11656"
                  class="plainText11656 subtitle-1"
                  hide-details="auto"
                >
                  b金庫合計
                </div>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-text-field
                  label="b金庫合計"
                  v-model="watchFromID11657"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11657"
                  class="textfield11657"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="6"
                sm="6"
                md="6"
                lg="6"
                class="text-right align-self-center"
              >
                <div
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11658"
                  class="plainText11658 subtitle-1"
                  hide-details="auto"
                >
                  c夜間金庫合計
                </div>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-text-field
                  label="c夜間金庫合計"
                  v-model="watchFromID11659"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11659"
                  class="textfield11659"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-divider
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11542"
                  class="divider11542"
                  :style="'border-bottom-width: 5px !important;'"
                  color="black"
                  hide-details="auto"
                >
                </v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="6"
                sm="6"
                md="6"
                lg="6"
                class="text-right align-self-center"
              >
                <div
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11660"
                  class="plainText11660 subtitle-1"
                  hide-details="auto"
                >
                  BNA内
                </div>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11661.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="BNA内"
                        v-model="data.Recodes.FID11661.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11661"
                        class="textfield11661"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11661.modal"
                    title="BNA内"
                    :value="data.Recodes.FID11661.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11661.modal = false),
                        $set(data.Recodes.FID11661, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="6"
                sm="6"
                md="6"
                lg="6"
                class="text-right align-self-center"
              >
                <div
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11662"
                  class="plainText11662 subtitle-1"
                  hide-details="auto"
                >
                  釣銭機内
                </div>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11663.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="釣銭機内"
                        v-model="data.Recodes.FID11663.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11663"
                        class="textfield11663"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11663.modal"
                    title="釣銭機内"
                    :value="data.Recodes.FID11663.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11663.modal = false),
                        $set(data.Recodes.FID11663, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="6"
                sm="6"
                md="6"
                lg="6"
                class="text-right align-self-center"
              >
                <div
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11664"
                  class="plainText11664 subtitle-1"
                  hide-details="auto"
                >
                  セルフ洗車機釣銭
                </div>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11665.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="セルフ洗浄機釣銭"
                        v-model="data.Recodes.FID11665.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11665"
                        class="textfield11665"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11665.modal"
                    title="セルフ洗浄機釣銭"
                    :value="data.Recodes.FID11665.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11665.modal = false),
                        $set(data.Recodes.FID11665, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-divider
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11666"
                  class="divider11666"
                  :style="'border-bottom-width: 5px !important;'"
                  color="black"
                  hide-details="auto"
                >
                </v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="6"
                sm="6"
                md="6"
                lg="6"
                class="text-right align-self-center"
              >
                <div
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11667"
                  class="plainText11667 subtitle-1"
                  hide-details="auto"
                >
                  POS現金残高
                </div>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11668.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="POS現金残高"
                        v-model="data.Recodes.FID11668.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11668"
                        class="textfield11668"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11668.modal"
                    title="POS現金残高"
                    :value="data.Recodes.FID11668.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11668.modal = false),
                        $set(data.Recodes.FID11668, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-divider
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11669"
                  class="divider11669"
                  :style="'border-bottom-width: 5px !important;'"
                  color="black"
                  hide-details="auto"
                >
                </v-divider>
              </v-col>
              <div v-show="false">{{ total11676 }}</div>
            </v-row>
            <v-row>
              <v-col
                cols="6"
                sm="6"
                md="6"
                lg="6"
                class="text-right align-self-center"
              >
                <div
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11670"
                  class="plainText11670 subtitle-1"
                  hide-details="auto"
                >
                  現金過不足
                </div>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-text-field
                  label="現金過不足"
                  v-model="subtraction11671"
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11671"
                  class="textfield11671"
                  outlined
                  readonly
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <div v-show="false">
                {{ calc11986sp(data.Recodes.FID11986, data.Recodes) }}
              </div>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-divider
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11672"
                  class="divider11672"
                  :style="'border-bottom-width: 5px !important;'"
                  color="black"
                  hide-details="auto"
                >
                </v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="6"
                sm="6"
                md="6"
                lg="6"
                class="text-right align-self-center"
              >
                <div
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11673"
                  class="plainText11673 subtitle-1"
                  hide-details="auto"
                >
                  釣銭取り忘れ
                </div>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6">
                <v-dialog
                  v-model="data.Recodes.FID11674.modal"
                  :max-width="resizeWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-text-field
                        label="釣銭取り忘れ"
                        v-model="data.Recodes.FID11674.value"
                        :disabled="allDisabled11679"
                        :rules="[rules.smallNum]"
                        background-color="white"
                        id="FID11674"
                        class="textfield11674"
                        append-icon="mdi-calculator"
                        @click:append="on.click"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field></div></template
                  ><input-Number
                    v-if="data.Recodes.FID11674.modal"
                    title="釣銭取り忘れ"
                    :value="data.Recodes.FID11674.value"
                    :rules="[rules.smallNum]"
                    v-on:ok="
                      (data.Recodes.FID11674.modal = false),
                        $set(data.Recodes.FID11674, 'value', $event)
                    "
                    type="calculator"
                    AC
                  ></input-Number>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-divider
                  label=""
                  :disabled="allDisabled11679"
                  background-color="white"
                  id="FID11675"
                  class="divider11675"
                  :style="'border-bottom-width: 5px !important;'"
                  color="black"
                  hide-details="auto"
                >
                </v-divider>
              </v-col>
            </v-row>
          </template>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-btn
                label="登録"
                :disabled="data.Recodes.FID11678.disabled || allDisabled11679"
                background-color="white"
                id="FID11678"
                class="button11678"
                @click="vtnModal11678 = true"
                color="primary"
                block
                style="font-size: 20px; letter-spacing: 10px"
                height="50"
                hide-details="auto"
              >
                登録</v-btn
              >
              <v-dialog v-model="vtnModal11678" max-width="400">
                <v-card>
                  <v-card-title class="text-h5">
                    現在の入力内容で登録を完了してもよろしいでしょうか？
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="mr-3"
                      color="green darken-1 white--text"
                      @click="onBtn11678(), (vtnModal11678 = false)"
                      large
                    >
                      はい
                    </v-btn>
                    <v-btn
                      color="red darken-1 white--text"
                      @click="vtnModal11678 = false"
                      large
                    >
                      いいえ
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="6" sm="6" md="4" lg="4">
              <v-btn
                label="▲"
                :disabled="data.Recodes.FID12268.disabled"
                background-color="white"
                id="FID12268"
                class="button12268"
                @click="onBtn12268"
                color="light-blue"
                small
                fixed
                :style="'right: 50px; bottom: 55px;'"
                hide-details="auto"
              >
                ▲</v-btn
              >
            </v-col>
            <v-col cols="auto" sm="auto" md="auto" lg="auto">
              <v-btn
                label="▼"
                background-color="white"
                id="FID13153"
                class="button13153"
                @click="onBtn13153"
                color="light-blue"
                small
                fixed
                :style="'right: 100px; bottom: 55px;'"
                hide-details="auto"
              >
                ▼</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col align="left">
              <v-btn
                @click="deleteModal = true"
                :disabled="data.ReportID == null || userRole == '一般'"
                color="red white--text"
                >削除
              </v-btn>
              <v-dialog v-model="deleteModal" max-width="400">
                <v-card>
                  <v-card-title class="text-h5"> 削除しますか？ </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="mr-3"
                      color="green darken-1 white--text"
                      @click="deleteModal = false"
                      large
                    >
                      いいえ
                    </v-btn>
                    <v-btn color="red darken-1 white--text" @click="del" large>
                      はい
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col v-if="showTemplate11677">
              <v-btn
                @click="save11677()"
                color="yellow"
                :disabled="data.Recodes.FID11677.disabled || allDisabled11679"
                fixed
                bottom
                :style="'right:+50px;'"
                >一時保存</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-container>
  </div>
</template>

<script>
import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");

import { moneyStatusCheck } from "./specialMethod";
import patrolreportCreateSpecial from "../views/patrolreportCreateSpecial";
import inputNumber from "../views/inputNumber";
import { cashdifference } from "./specialMethod";
import { updatePatrolreport } from "./specialMethod";
import patrolReportNavigationMenu from "../views/patrolReportNavigationMenu";

export default {
  name: "Home",
  components: {
    patrolreportCreateSpecial,
    inputNumber,
    patrolReportNavigationMenu,
  },
  data() {
    return {
      isLoading: false,
      escapeValidation: false,
      showChild: true,
      subInit: false,
      disabled: false,
      data: {
        PageID: 1655,
        ReportID: null, //新規ならnull、更新ならidがある
        Recodes: {
          FID11340: { value: "", formType: 1, disabled: true },
          FID11842: { value: "", formType: 1, disabled: false },
          FID11931: { value: "", formType: 1, disabled: false },
          FID12223: { value: "", formType: 1, disabled: false },
          FID11341: { value: "", formType: 1, disabled: true },
          FID11342: { value: "", formType: 1, disabled: true },
          FID11343: { value: "", formType: 1, disabled: true },
          FID13154: { value: "", formType: 1, disabled: false },
          FID11869: { value: "", formType: 1, disabled: false },
          FID11345: { value: "", formType: 16, disabled: false },
          FID11346: { value: "", formType: 1, disabled: true },
          FID11349: { value: "", formType: 18, disabled: false },
          FID11351: {
            value: "a レジ内現金金種内訳",
            formType: 1,
            disabled: false,
          },
          FID11411: { value: "10000", formType: 1, disabled: false },
          FID11412: { value: "", formType: 1, disabled: false, modal: false },
          FID11413: { value: "", formType: 1, disabled: false },
          FID11415: { value: "5000", formType: 1, disabled: false },
          FID11417: { value: "", formType: 1, disabled: false, modal: false },
          FID11418: { value: "", formType: 1, disabled: false },
          FID11419: { value: "2000", formType: 1, disabled: false },
          FID11421: { value: "", formType: 1, disabled: false, modal: false },
          FID11422: { value: "", formType: 1, disabled: false },
          FID11425: { value: "1000", formType: 1, disabled: false },
          FID11427: { value: "", formType: 1, disabled: false, modal: false },
          FID11428: { value: "", formType: 1, disabled: false },
          FID11429: { value: "500", formType: 1, disabled: false },
          FID11430: { value: "", formType: 1, disabled: false, modal: false },
          FID11431: { value: "", formType: 1, disabled: false, modal: false },
          FID11432: { value: "", formType: 1, disabled: false },
          FID11436: { value: "25000", formType: 1, disabled: false },
          FID11434: { value: "", formType: 1, disabled: false },
          FID11435: { value: "", formType: 1, disabled: false },
          FID11437: { value: "100", formType: 1, disabled: false },
          FID11438: { value: "", formType: 1, disabled: false, modal: false },
          FID11439: { value: "", formType: 1, disabled: false, modal: false },
          FID11440: { value: "", formType: 1, disabled: false },
          FID11682: { value: "5000", formType: 1, disabled: false },
          FID11443: { value: "", formType: 1, disabled: false },
          FID11444: { value: "", formType: 1, disabled: false },
          FID11445: { value: "50", formType: 1, disabled: false },
          FID11446: { value: "", formType: 1, disabled: false, modal: false },
          FID11447: { value: "", formType: 1, disabled: false, modal: false },
          FID11448: { value: "", formType: 1, disabled: false },
          FID11683: { value: "2500", formType: 1, disabled: false },
          FID11451: { value: "", formType: 1, disabled: false },
          FID11452: { value: "", formType: 1, disabled: false },
          FID11453: { value: "10", formType: 1, disabled: false },
          FID11454: { value: "", formType: 1, disabled: false, modal: false },
          FID11455: { value: "", formType: 1, disabled: false, modal: false },
          FID11456: { value: "", formType: 1, disabled: false },
          FID11684: { value: "500", formType: 1, disabled: false },
          FID11459: { value: "", formType: 1, disabled: false },
          FID11460: { value: "", formType: 1, disabled: false },
          FID11461: { value: "5", formType: 1, disabled: false },
          FID11462: { value: "", formType: 1, disabled: false, modal: false },
          FID11463: { value: "", formType: 1, disabled: false, modal: false },
          FID11464: { value: "", formType: 1, disabled: false },
          FID11685: { value: "250", formType: 1, disabled: false },
          FID11467: { value: "", formType: 1, disabled: false },
          FID11468: { value: "", formType: 1, disabled: false },
          FID11469: { value: "1", formType: 1, disabled: false },
          FID11470: { value: "", formType: 1, disabled: false, modal: false },
          FID11471: { value: "", formType: 1, disabled: false, modal: false },
          FID11472: { value: "", formType: 1, disabled: false },
          FID11686: { value: "50", formType: 1, disabled: false },
          FID11475: { value: "", formType: 1, disabled: false },
          FID11476: { value: "", formType: 1, disabled: false },
          FID11483: { value: "", formType: 1, disabled: false },
          FID11503: {
            value: "b金庫内現金金種内訳",
            formType: 1,
            disabled: false,
          },
          FID11505: { value: "10000", formType: 1, disabled: false },
          FID11507: { value: "", formType: 1, disabled: false, modal: false },
          FID11508: { value: "", formType: 1, disabled: false },
          FID11543: { value: "5000", formType: 1, disabled: false },
          FID11545: { value: "", formType: 1, disabled: false, modal: false },
          FID11546: { value: "", formType: 1, disabled: false },
          FID11547: { value: "2000", formType: 1, disabled: false },
          FID11549: { value: "", formType: 1, disabled: false, modal: false },
          FID11550: { value: "", formType: 1, disabled: false },
          FID11551: { value: "1000", formType: 1, disabled: false },
          FID11553: { value: "", formType: 1, disabled: false, modal: false },
          FID11554: { value: "", formType: 1, disabled: false },
          FID11511: { value: "500", formType: 1, disabled: false },
          FID11512: { value: "", formType: 1, disabled: false, modal: false },
          FID11513: { value: "", formType: 1, disabled: false, modal: false },
          FID11514: { value: "", formType: 1, disabled: false },
          FID11687: { value: "25000", formType: 1, disabled: false },
          FID11517: { value: "", formType: 1, disabled: false },
          FID11518: { value: "", formType: 1, disabled: false },
          FID11555: { value: "100", formType: 1, disabled: false },
          FID11556: { value: "", formType: 1, disabled: false, modal: false },
          FID11557: { value: "", formType: 1, disabled: false, modal: false },
          FID11558: { value: "", formType: 1, disabled: false },
          FID11688: { value: "5000", formType: 1, disabled: false },
          FID11561: { value: "", formType: 1, disabled: false },
          FID11562: { value: "", formType: 1, disabled: false },
          FID11563: { value: "50", formType: 1, disabled: false },
          FID11571: { value: "", formType: 1, disabled: false, modal: false },
          FID11572: { value: "", formType: 1, disabled: false, modal: false },
          FID11573: { value: "", formType: 1, disabled: false },
          FID11689: { value: "2500", formType: 1, disabled: false },
          FID11575: { value: "", formType: 1, disabled: false },
          FID11576: { value: "", formType: 1, disabled: false },
          FID11565: { value: "10", formType: 1, disabled: false },
          FID11577: { value: "", formType: 1, disabled: false, modal: false },
          FID11578: { value: "", formType: 1, disabled: false, modal: false },
          FID11579: { value: "", formType: 1, disabled: false },
          FID11690: { value: "500", formType: 1, disabled: false },
          FID11581: { value: "", formType: 1, disabled: false },
          FID11582: { value: "", formType: 1, disabled: false },
          FID11567: { value: "5", formType: 1, disabled: false },
          FID11583: { value: "", formType: 1, disabled: false, modal: false },
          FID11584: { value: "", formType: 1, disabled: false, modal: false },
          FID11585: { value: "", formType: 1, disabled: false },
          FID11691: { value: "250", formType: 1, disabled: false },
          FID11587: { value: "", formType: 1, disabled: false },
          FID11588: { value: "", formType: 1, disabled: false },
          FID11569: { value: "1", formType: 1, disabled: false },
          FID11589: { value: "", formType: 1, disabled: false, modal: false },
          FID11590: { value: "", formType: 1, disabled: false, modal: false },
          FID11591: { value: "", formType: 1, disabled: false },
          FID11692: { value: "50", formType: 1, disabled: false },
          FID11593: { value: "", formType: 1, disabled: false },
          FID11594: { value: "", formType: 1, disabled: false },
          FID11521: { value: "", formType: 1, disabled: false },
          FID11504: {
            value: "c夜間業務用金庫内現金金種内訳",
            formType: 1,
            disabled: false,
          },
          FID11522: { value: "10000", formType: 1, disabled: false },
          FID11524: { value: "", formType: 1, disabled: false, modal: false },
          FID11525: { value: "", formType: 1, disabled: false },
          FID11599: { value: "5000", formType: 1, disabled: false },
          FID11601: { value: "", formType: 1, disabled: false, modal: false },
          FID11602: { value: "", formType: 1, disabled: false },
          FID11603: { value: "2000", formType: 1, disabled: false },
          FID11605: { value: "", formType: 1, disabled: false, modal: false },
          FID11606: { value: "", formType: 1, disabled: false },
          FID11607: { value: "1000", formType: 1, disabled: false },
          FID11609: { value: "", formType: 1, disabled: false, modal: false },
          FID11610: { value: "", formType: 1, disabled: false },
          FID11528: { value: "500", formType: 1, disabled: false },
          FID11529: { value: "", formType: 1, disabled: false, modal: false },
          FID11530: { value: "", formType: 1, disabled: false, modal: false },
          FID11531: { value: "", formType: 1, disabled: false },
          FID11693: { value: "25000", formType: 1, disabled: false },
          FID11534: { value: "", formType: 1, disabled: false },
          FID11535: { value: "", formType: 1, disabled: false },
          FID11612: { value: "100", formType: 1, disabled: false },
          FID11613: { value: "", formType: 1, disabled: false, modal: false },
          FID11614: { value: "", formType: 1, disabled: false, modal: false },
          FID11615: { value: "", formType: 1, disabled: false },
          FID11694: { value: "5000", formType: 1, disabled: false },
          FID11633: { value: "", formType: 1, disabled: false },
          FID11643: { value: "", formType: 1, disabled: false },
          FID11616: { value: "50", formType: 1, disabled: false },
          FID11617: { value: "", formType: 1, disabled: false, modal: false },
          FID11618: { value: "", formType: 1, disabled: false, modal: false },
          FID11619: { value: "", formType: 1, disabled: false },
          FID11695: { value: "2500", formType: 1, disabled: false },
          FID11635: { value: "", formType: 1, disabled: false },
          FID11645: { value: "", formType: 1, disabled: false },
          FID11620: { value: "10", formType: 1, disabled: false },
          FID11621: { value: "", formType: 1, disabled: false, modal: false },
          FID11622: { value: "", formType: 1, disabled: false, modal: false },
          FID11623: { value: "", formType: 1, disabled: false },
          FID11696: { value: "500", formType: 1, disabled: false },
          FID11637: { value: "", formType: 1, disabled: false },
          FID11647: { value: "", formType: 1, disabled: false },
          FID11624: { value: "5", formType: 1, disabled: false },
          FID11625: { value: "", formType: 1, disabled: false, modal: false },
          FID11626: { value: "", formType: 1, disabled: false, modal: false },
          FID11627: { value: "", formType: 1, disabled: false },
          FID11697: { value: "250", formType: 1, disabled: false },
          FID11639: { value: "", formType: 1, disabled: false },
          FID11649: { value: "", formType: 1, disabled: false },
          FID11628: { value: "1", formType: 1, disabled: false },
          FID11629: { value: "", formType: 1, disabled: false, modal: false },
          FID11630: { value: "", formType: 1, disabled: false, modal: false },
          FID11631: { value: "", formType: 1, disabled: false },
          FID11698: { value: "50", formType: 1, disabled: false },
          FID11641: { value: "", formType: 1, disabled: false },
          FID11651: { value: "", formType: 1, disabled: false },
          FID11538: { value: "", formType: 1, disabled: false },
          FID11539: { value: "合計計算", formType: 1, disabled: false },
          FID11541: { value: "", formType: 1, disabled: false },
          FID11657: { value: "", formType: 1, disabled: false },
          FID11659: { value: "", formType: 1, disabled: false },
          FID11661: { value: "", formType: 1, disabled: false, modal: false },
          FID11663: { value: "", formType: 1, disabled: false, modal: false },
          FID11665: { value: "", formType: 1, disabled: false, modal: false },
          FID11668: { value: "", formType: 1, disabled: false, modal: false },
          FID11676: { value: "", formType: 1, disabled: false },
          FID11671: { value: "", formType: 1, disabled: false },
          FID11986: { value: "", formType: 1, disabled: false },
          FID11674: { value: "", formType: 1, disabled: false, modal: false },
          FID11677: { value: "", formType: 20, disabled: false },
          FID11678: { value: "", formType: 16, disabled: true },
          FID12268: { value: "", formType: 16, disabled: false },
          FID13153: { value: "", formType: 16, disabled: false },
          FID13378: { value: "", formType: 1, disabled: false }, //事業部コード
        },
        DeleteSubReports: [],
      },
      vtnModal11678: false,

      rules: {
        required: (value) => !!value || "必須項目です",
        smallNum: (value) => {
          return /^(\d*.?\d+)*$/.test(value) || "数値のみ入力可能です。";
        },
      },
      deleteModal: false,
      copyFlag: false,
      sendCopyFlag: false,
      noBack: false,
      backCount: -1,
      inspectionItem: "0", //内訳項目選択
      inspectionItems: [
        { value: "0", label: "全て" },
        {
          value: "1",
          label: "aレジ内現金金種内訳",
        },
        {
          value: "2",
          label: "b金庫内現金金種内訳",
        },
        {
          value: "3",
          label: "c夜間業務用金庫内現金金種内訳",
        },
        {
          value: "4",
          label: "合計計算",
        },
      ],
      isShowInspection1: true,
      isShowInspection2: true,
      isShowInspection3: true,
      isShowInspection4: true,
    };
  },
  async created() {
    await this.getInit();
  },

  computed: {
    watchFromID11346: function () {
      let value = this.data.Recodes.FID11345.value;
      this.setWatchFromID11346(value);
      return value;
    },
    compClass11351: function () {
      return "amber";
    },
    resizeWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "270px";
        case "sm":
          return "500px";
        case "md":
          return "600px";
        case "lg":
          return "500px";
        case "xl":
          return "500px";
        default:
          return "500px";
      }
    },
    multi11413: function () {
      let multi =
        typeof this.data.Recodes.FID11411.value == "undefined" ||
        isNaN(this.data.Recodes.FID11411.value)
          ? 0
          : Number(this.data.Recodes.FID11411.value);
      multi *=
        typeof this.data.Recodes.FID11412.value == "undefined" ||
        isNaN(this.data.Recodes.FID11412.value)
          ? 0
          : Number(this.data.Recodes.FID11412.value);

      this.setMulti11413(multi);
      return multi;
    },
    multi11418: function () {
      let multi =
        typeof this.data.Recodes.FID11415.value == "undefined" ||
        isNaN(this.data.Recodes.FID11415.value)
          ? 0
          : Number(this.data.Recodes.FID11415.value);
      multi *=
        typeof this.data.Recodes.FID11417.value == "undefined" ||
        isNaN(this.data.Recodes.FID11417.value)
          ? 0
          : Number(this.data.Recodes.FID11417.value);

      this.setMulti11418(multi);
      return multi;
    },
    multi11422: function () {
      let multi =
        typeof this.data.Recodes.FID11419.value == "undefined" ||
        isNaN(this.data.Recodes.FID11419.value)
          ? 0
          : Number(this.data.Recodes.FID11419.value);
      multi *=
        typeof this.data.Recodes.FID11421.value == "undefined" ||
        isNaN(this.data.Recodes.FID11421.value)
          ? 0
          : Number(this.data.Recodes.FID11421.value);

      this.setMulti11422(multi);
      return multi;
    },
    multi11428: function () {
      let multi =
        typeof this.data.Recodes.FID11425.value == "undefined" ||
        isNaN(this.data.Recodes.FID11425.value)
          ? 0
          : Number(this.data.Recodes.FID11425.value);
      multi *=
        typeof this.data.Recodes.FID11427.value == "undefined" ||
        isNaN(this.data.Recodes.FID11427.value)
          ? 0
          : Number(this.data.Recodes.FID11427.value);

      this.setMulti11428(multi);
      return multi;
    },
    total11432: function () {
      let total = 0;
      total +=
        typeof this.data.Recodes.FID11434.value == "undefined" ||
        isNaN(this.data.Recodes.FID11434.value)
          ? 0
          : Number(this.data.Recodes.FID11434.value);
      total +=
        typeof this.data.Recodes.FID11435.value == "undefined" ||
        isNaN(this.data.Recodes.FID11435.value)
          ? 0
          : Number(this.data.Recodes.FID11435.value);
      this.setTotal11432(total);
      return total;
    },
    compClass11436: function () {
      return "grey";
    },
    compClass11434: function () {
      return "grey";
    },
    multi11434: function () {
      let multi =
        typeof this.data.Recodes.FID11436.value == "undefined" ||
        isNaN(this.data.Recodes.FID11436.value)
          ? 0
          : Number(this.data.Recodes.FID11436.value);
      multi *=
        typeof this.data.Recodes.FID11430.value == "undefined" ||
        isNaN(this.data.Recodes.FID11430.value)
          ? 0
          : Number(this.data.Recodes.FID11430.value);

      this.setMulti11434(multi);
      return multi;
    },
    compClass11435: function () {
      return "grey";
    },
    multi11435: function () {
      let multi =
        typeof this.data.Recodes.FID11429.value == "undefined" ||
        isNaN(this.data.Recodes.FID11429.value)
          ? 0
          : Number(this.data.Recodes.FID11429.value);
      multi *=
        typeof this.data.Recodes.FID11431.value == "undefined" ||
        isNaN(this.data.Recodes.FID11431.value)
          ? 0
          : Number(this.data.Recodes.FID11431.value);

      this.setMulti11435(multi);
      return multi;
    },
    total11440: function () {
      let total = 0;
      total +=
        typeof this.data.Recodes.FID11443.value == "undefined" ||
        isNaN(this.data.Recodes.FID11443.value)
          ? 0
          : Number(this.data.Recodes.FID11443.value);
      total +=
        typeof this.data.Recodes.FID11444.value == "undefined" ||
        isNaN(this.data.Recodes.FID11444.value)
          ? 0
          : Number(this.data.Recodes.FID11444.value);
      this.setTotal11440(total);
      return total;
    },
    compClass11682: function () {
      return "grey";
    },
    compClass11443: function () {
      return "grey";
    },
    multi11443: function () {
      let multi =
        typeof this.data.Recodes.FID11682.value == "undefined" ||
        isNaN(this.data.Recodes.FID11682.value)
          ? 0
          : Number(this.data.Recodes.FID11682.value);
      multi *=
        typeof this.data.Recodes.FID11438.value == "undefined" ||
        isNaN(this.data.Recodes.FID11438.value)
          ? 0
          : Number(this.data.Recodes.FID11438.value);

      this.setMulti11443(multi);
      return multi;
    },
    compClass11444: function () {
      return "grey";
    },
    multi11444: function () {
      let multi =
        typeof this.data.Recodes.FID11437.value == "undefined" ||
        isNaN(this.data.Recodes.FID11437.value)
          ? 0
          : Number(this.data.Recodes.FID11437.value);
      multi *=
        typeof this.data.Recodes.FID11439.value == "undefined" ||
        isNaN(this.data.Recodes.FID11439.value)
          ? 0
          : Number(this.data.Recodes.FID11439.value);

      this.setMulti11444(multi);
      return multi;
    },
    total11448: function () {
      let total = 0;
      total +=
        typeof this.data.Recodes.FID11451.value == "undefined" ||
        isNaN(this.data.Recodes.FID11451.value)
          ? 0
          : Number(this.data.Recodes.FID11451.value);
      total +=
        typeof this.data.Recodes.FID11452.value == "undefined" ||
        isNaN(this.data.Recodes.FID11452.value)
          ? 0
          : Number(this.data.Recodes.FID11452.value);
      this.setTotal11448(total);
      return total;
    },
    compClass11683: function () {
      return "grey";
    },
    compClass11451: function () {
      return "grey";
    },
    multi11451: function () {
      let multi =
        typeof this.data.Recodes.FID11683.value == "undefined" ||
        isNaN(this.data.Recodes.FID11683.value)
          ? 0
          : Number(this.data.Recodes.FID11683.value);
      multi *=
        typeof this.data.Recodes.FID11446.value == "undefined" ||
        isNaN(this.data.Recodes.FID11446.value)
          ? 0
          : Number(this.data.Recodes.FID11446.value);

      this.setMulti11451(multi);
      return multi;
    },
    compClass11452: function () {
      return "grey";
    },
    multi11452: function () {
      let multi =
        typeof this.data.Recodes.FID11445.value == "undefined" ||
        isNaN(this.data.Recodes.FID11445.value)
          ? 0
          : Number(this.data.Recodes.FID11445.value);
      multi *=
        typeof this.data.Recodes.FID11447.value == "undefined" ||
        isNaN(this.data.Recodes.FID11447.value)
          ? 0
          : Number(this.data.Recodes.FID11447.value);

      this.setMulti11452(multi);
      return multi;
    },
    total11456: function () {
      let total = 0;
      total +=
        typeof this.data.Recodes.FID11459.value == "undefined" ||
        isNaN(this.data.Recodes.FID11459.value)
          ? 0
          : Number(this.data.Recodes.FID11459.value);
      total +=
        typeof this.data.Recodes.FID11460.value == "undefined" ||
        isNaN(this.data.Recodes.FID11460.value)
          ? 0
          : Number(this.data.Recodes.FID11460.value);
      this.setTotal11456(total);
      return total;
    },
    compClass11684: function () {
      return "grey";
    },
    compClass11459: function () {
      return "grey";
    },
    multi11459: function () {
      let multi =
        typeof this.data.Recodes.FID11684.value == "undefined" ||
        isNaN(this.data.Recodes.FID11684.value)
          ? 0
          : Number(this.data.Recodes.FID11684.value);
      multi *=
        typeof this.data.Recodes.FID11454.value == "undefined" ||
        isNaN(this.data.Recodes.FID11454.value)
          ? 0
          : Number(this.data.Recodes.FID11454.value);

      this.setMulti11459(multi);
      return multi;
    },
    compClass11460: function () {
      return "grey";
    },
    multi11460: function () {
      let multi =
        typeof this.data.Recodes.FID11453.value == "undefined" ||
        isNaN(this.data.Recodes.FID11453.value)
          ? 0
          : Number(this.data.Recodes.FID11453.value);
      multi *=
        typeof this.data.Recodes.FID11455.value == "undefined" ||
        isNaN(this.data.Recodes.FID11455.value)
          ? 0
          : Number(this.data.Recodes.FID11455.value);

      this.setMulti11460(multi);
      return multi;
    },
    total11464: function () {
      let total = 0;
      total +=
        typeof this.data.Recodes.FID11467.value == "undefined" ||
        isNaN(this.data.Recodes.FID11467.value)
          ? 0
          : Number(this.data.Recodes.FID11467.value);
      total +=
        typeof this.data.Recodes.FID11468.value == "undefined" ||
        isNaN(this.data.Recodes.FID11468.value)
          ? 0
          : Number(this.data.Recodes.FID11468.value);
      this.setTotal11464(total);
      return total;
    },
    compClass11685: function () {
      return "grey";
    },
    compClass11467: function () {
      return "grey";
    },
    multi11467: function () {
      let multi =
        typeof this.data.Recodes.FID11685.value == "undefined" ||
        isNaN(this.data.Recodes.FID11685.value)
          ? 0
          : Number(this.data.Recodes.FID11685.value);
      multi *=
        typeof this.data.Recodes.FID11462.value == "undefined" ||
        isNaN(this.data.Recodes.FID11462.value)
          ? 0
          : Number(this.data.Recodes.FID11462.value);

      this.setMulti11467(multi);
      return multi;
    },
    compClass11468: function () {
      return "grey";
    },
    multi11468: function () {
      let multi =
        typeof this.data.Recodes.FID11461.value == "undefined" ||
        isNaN(this.data.Recodes.FID11461.value)
          ? 0
          : Number(this.data.Recodes.FID11461.value);
      multi *=
        typeof this.data.Recodes.FID11463.value == "undefined" ||
        isNaN(this.data.Recodes.FID11463.value)
          ? 0
          : Number(this.data.Recodes.FID11463.value);

      this.setMulti11468(multi);
      return multi;
    },
    total11472: function () {
      let total = 0;
      total +=
        typeof this.data.Recodes.FID11475.value == "undefined" ||
        isNaN(this.data.Recodes.FID11475.value)
          ? 0
          : Number(this.data.Recodes.FID11475.value);
      total +=
        typeof this.data.Recodes.FID11476.value == "undefined" ||
        isNaN(this.data.Recodes.FID11476.value)
          ? 0
          : Number(this.data.Recodes.FID11476.value);
      this.setTotal11472(total);
      return total;
    },
    compClass11686: function () {
      return "grey";
    },
    compClass11475: function () {
      return "grey";
    },
    multi11475: function () {
      let multi =
        typeof this.data.Recodes.FID11686.value == "undefined" ||
        isNaN(this.data.Recodes.FID11686.value)
          ? 0
          : Number(this.data.Recodes.FID11686.value);
      multi *=
        typeof this.data.Recodes.FID11470.value == "undefined" ||
        isNaN(this.data.Recodes.FID11470.value)
          ? 0
          : Number(this.data.Recodes.FID11470.value);

      this.setMulti11475(multi);
      return multi;
    },
    compClass11476: function () {
      return "grey";
    },
    multi11476: function () {
      let multi =
        typeof this.data.Recodes.FID11469.value == "undefined" ||
        isNaN(this.data.Recodes.FID11469.value)
          ? 0
          : Number(this.data.Recodes.FID11469.value);
      multi *=
        typeof this.data.Recodes.FID11471.value == "undefined" ||
        isNaN(this.data.Recodes.FID11471.value)
          ? 0
          : Number(this.data.Recodes.FID11471.value);

      this.setMulti11476(multi);
      return multi;
    },
    total11483: function () {
      let total = 0;
      total +=
        typeof this.data.Recodes.FID11413.value == "undefined" ||
        isNaN(this.data.Recodes.FID11413.value)
          ? 0
          : Number(this.data.Recodes.FID11413.value);
      total +=
        typeof this.data.Recodes.FID11418.value == "undefined" ||
        isNaN(this.data.Recodes.FID11418.value)
          ? 0
          : Number(this.data.Recodes.FID11418.value);
      total +=
        typeof this.data.Recodes.FID11422.value == "undefined" ||
        isNaN(this.data.Recodes.FID11422.value)
          ? 0
          : Number(this.data.Recodes.FID11422.value);
      total +=
        typeof this.data.Recodes.FID11428.value == "undefined" ||
        isNaN(this.data.Recodes.FID11428.value)
          ? 0
          : Number(this.data.Recodes.FID11428.value);
      total +=
        typeof this.data.Recodes.FID11432.value == "undefined" ||
        isNaN(this.data.Recodes.FID11432.value)
          ? 0
          : Number(this.data.Recodes.FID11432.value);
      total +=
        typeof this.data.Recodes.FID11440.value == "undefined" ||
        isNaN(this.data.Recodes.FID11440.value)
          ? 0
          : Number(this.data.Recodes.FID11440.value);
      total +=
        typeof this.data.Recodes.FID11448.value == "undefined" ||
        isNaN(this.data.Recodes.FID11448.value)
          ? 0
          : Number(this.data.Recodes.FID11448.value);
      total +=
        typeof this.data.Recodes.FID11456.value == "undefined" ||
        isNaN(this.data.Recodes.FID11456.value)
          ? 0
          : Number(this.data.Recodes.FID11456.value);
      total +=
        typeof this.data.Recodes.FID11464.value == "undefined" ||
        isNaN(this.data.Recodes.FID11464.value)
          ? 0
          : Number(this.data.Recodes.FID11464.value);
      total +=
        typeof this.data.Recodes.FID11472.value == "undefined" ||
        isNaN(this.data.Recodes.FID11472.value)
          ? 0
          : Number(this.data.Recodes.FID11472.value);
      this.setTotal11483(total);
      return total;
    },
    compClass11503: function () {
      return "amber";
    },
    multi11508: function () {
      let multi =
        typeof this.data.Recodes.FID11505.value == "undefined" ||
        isNaN(this.data.Recodes.FID11505.value)
          ? 0
          : Number(this.data.Recodes.FID11505.value);
      multi *=
        typeof this.data.Recodes.FID11507.value == "undefined" ||
        isNaN(this.data.Recodes.FID11507.value)
          ? 0
          : Number(this.data.Recodes.FID11507.value);

      this.setMulti11508(multi);
      return multi;
    },
    multi11546: function () {
      let multi =
        typeof this.data.Recodes.FID11543.value == "undefined" ||
        isNaN(this.data.Recodes.FID11543.value)
          ? 0
          : Number(this.data.Recodes.FID11543.value);
      multi *=
        typeof this.data.Recodes.FID11545.value == "undefined" ||
        isNaN(this.data.Recodes.FID11545.value)
          ? 0
          : Number(this.data.Recodes.FID11545.value);

      this.setMulti11546(multi);
      return multi;
    },
    multi11550: function () {
      let multi =
        typeof this.data.Recodes.FID11547.value == "undefined" ||
        isNaN(this.data.Recodes.FID11547.value)
          ? 0
          : Number(this.data.Recodes.FID11547.value);
      multi *=
        typeof this.data.Recodes.FID11549.value == "undefined" ||
        isNaN(this.data.Recodes.FID11549.value)
          ? 0
          : Number(this.data.Recodes.FID11549.value);

      this.setMulti11550(multi);
      return multi;
    },
    multi11554: function () {
      let multi =
        typeof this.data.Recodes.FID11551.value == "undefined" ||
        isNaN(this.data.Recodes.FID11551.value)
          ? 0
          : Number(this.data.Recodes.FID11551.value);
      multi *=
        typeof this.data.Recodes.FID11553.value == "undefined" ||
        isNaN(this.data.Recodes.FID11553.value)
          ? 0
          : Number(this.data.Recodes.FID11553.value);

      this.setMulti11554(multi);
      return multi;
    },
    total11514: function () {
      let total = 0;
      total +=
        typeof this.data.Recodes.FID11517.value == "undefined" ||
        isNaN(this.data.Recodes.FID11517.value)
          ? 0
          : Number(this.data.Recodes.FID11517.value);
      total +=
        typeof this.data.Recodes.FID11518.value == "undefined" ||
        isNaN(this.data.Recodes.FID11518.value)
          ? 0
          : Number(this.data.Recodes.FID11518.value);
      this.setTotal11514(total);
      return total;
    },
    compClass11687: function () {
      return "grey";
    },
    compClass11517: function () {
      return "grey";
    },
    multi11517: function () {
      let multi =
        typeof this.data.Recodes.FID11687.value == "undefined" ||
        isNaN(this.data.Recodes.FID11687.value)
          ? 0
          : Number(this.data.Recodes.FID11687.value);
      multi *=
        typeof this.data.Recodes.FID11512.value == "undefined" ||
        isNaN(this.data.Recodes.FID11512.value)
          ? 0
          : Number(this.data.Recodes.FID11512.value);

      this.setMulti11517(multi);
      return multi;
    },
    compClass11518: function () {
      return "grey";
    },
    multi11518: function () {
      let multi =
        typeof this.data.Recodes.FID11511.value == "undefined" ||
        isNaN(this.data.Recodes.FID11511.value)
          ? 0
          : Number(this.data.Recodes.FID11511.value);
      multi *=
        typeof this.data.Recodes.FID11513.value == "undefined" ||
        isNaN(this.data.Recodes.FID11513.value)
          ? 0
          : Number(this.data.Recodes.FID11513.value);

      this.setMulti11518(multi);
      return multi;
    },
    total11558: function () {
      let total = 0;
      total +=
        typeof this.data.Recodes.FID11561.value == "undefined" ||
        isNaN(this.data.Recodes.FID11561.value)
          ? 0
          : Number(this.data.Recodes.FID11561.value);
      total +=
        typeof this.data.Recodes.FID11562.value == "undefined" ||
        isNaN(this.data.Recodes.FID11562.value)
          ? 0
          : Number(this.data.Recodes.FID11562.value);
      this.setTotal11558(total);
      return total;
    },
    compClass11688: function () {
      return "grey";
    },
    compClass11561: function () {
      return "grey";
    },
    multi11561: function () {
      let multi =
        typeof this.data.Recodes.FID11688.value == "undefined" ||
        isNaN(this.data.Recodes.FID11688.value)
          ? 0
          : Number(this.data.Recodes.FID11688.value);
      multi *=
        typeof this.data.Recodes.FID11556.value == "undefined" ||
        isNaN(this.data.Recodes.FID11556.value)
          ? 0
          : Number(this.data.Recodes.FID11556.value);

      this.setMulti11561(multi);
      return multi;
    },
    compClass11562: function () {
      return "grey";
    },
    multi11562: function () {
      let multi =
        typeof this.data.Recodes.FID11555.value == "undefined" ||
        isNaN(this.data.Recodes.FID11555.value)
          ? 0
          : Number(this.data.Recodes.FID11555.value);
      multi *=
        typeof this.data.Recodes.FID11557.value == "undefined" ||
        isNaN(this.data.Recodes.FID11557.value)
          ? 0
          : Number(this.data.Recodes.FID11557.value);

      this.setMulti11562(multi);
      return multi;
    },
    total11573: function () {
      let total = 0;
      total +=
        typeof this.data.Recodes.FID11575.value == "undefined" ||
        isNaN(this.data.Recodes.FID11575.value)
          ? 0
          : Number(this.data.Recodes.FID11575.value);
      total +=
        typeof this.data.Recodes.FID11576.value == "undefined" ||
        isNaN(this.data.Recodes.FID11576.value)
          ? 0
          : Number(this.data.Recodes.FID11576.value);
      this.setTotal11573(total);
      return total;
    },
    compClass11689: function () {
      return "grey";
    },
    compClass11575: function () {
      return "grey";
    },
    multi11575: function () {
      let multi =
        typeof this.data.Recodes.FID11689.value == "undefined" ||
        isNaN(this.data.Recodes.FID11689.value)
          ? 0
          : Number(this.data.Recodes.FID11689.value);
      multi *=
        typeof this.data.Recodes.FID11571.value == "undefined" ||
        isNaN(this.data.Recodes.FID11571.value)
          ? 0
          : Number(this.data.Recodes.FID11571.value);

      this.setMulti11575(multi);
      return multi;
    },
    compClass11576: function () {
      return "grey";
    },
    multi11576: function () {
      let multi =
        typeof this.data.Recodes.FID11563.value == "undefined" ||
        isNaN(this.data.Recodes.FID11563.value)
          ? 0
          : Number(this.data.Recodes.FID11563.value);
      multi *=
        typeof this.data.Recodes.FID11572.value == "undefined" ||
        isNaN(this.data.Recodes.FID11572.value)
          ? 0
          : Number(this.data.Recodes.FID11572.value);

      this.setMulti11576(multi);
      return multi;
    },
    total11579: function () {
      let total = 0;
      total +=
        typeof this.data.Recodes.FID11581.value == "undefined" ||
        isNaN(this.data.Recodes.FID11581.value)
          ? 0
          : Number(this.data.Recodes.FID11581.value);
      total +=
        typeof this.data.Recodes.FID11582.value == "undefined" ||
        isNaN(this.data.Recodes.FID11582.value)
          ? 0
          : Number(this.data.Recodes.FID11582.value);
      this.setTotal11579(total);
      return total;
    },
    compClass11690: function () {
      return "grey";
    },
    compClass11581: function () {
      return "grey";
    },
    multi11581: function () {
      let multi =
        typeof this.data.Recodes.FID11690.value == "undefined" ||
        isNaN(this.data.Recodes.FID11690.value)
          ? 0
          : Number(this.data.Recodes.FID11690.value);
      multi *=
        typeof this.data.Recodes.FID11577.value == "undefined" ||
        isNaN(this.data.Recodes.FID11577.value)
          ? 0
          : Number(this.data.Recodes.FID11577.value);

      this.setMulti11581(multi);
      return multi;
    },
    compClass11582: function () {
      return "grey";
    },
    multi11582: function () {
      let multi =
        typeof this.data.Recodes.FID11565.value == "undefined" ||
        isNaN(this.data.Recodes.FID11565.value)
          ? 0
          : Number(this.data.Recodes.FID11565.value);
      multi *=
        typeof this.data.Recodes.FID11578.value == "undefined" ||
        isNaN(this.data.Recodes.FID11578.value)
          ? 0
          : Number(this.data.Recodes.FID11578.value);

      this.setMulti11582(multi);
      return multi;
    },
    total11585: function () {
      let total = 0;
      total +=
        typeof this.data.Recodes.FID11587.value == "undefined" ||
        isNaN(this.data.Recodes.FID11587.value)
          ? 0
          : Number(this.data.Recodes.FID11587.value);
      total +=
        typeof this.data.Recodes.FID11588.value == "undefined" ||
        isNaN(this.data.Recodes.FID11588.value)
          ? 0
          : Number(this.data.Recodes.FID11588.value);
      this.setTotal11585(total);
      return total;
    },
    compClass11691: function () {
      return "grey";
    },
    compClass11587: function () {
      return "grey";
    },
    multi11587: function () {
      let multi =
        typeof this.data.Recodes.FID11691.value == "undefined" ||
        isNaN(this.data.Recodes.FID11691.value)
          ? 0
          : Number(this.data.Recodes.FID11691.value);
      multi *=
        typeof this.data.Recodes.FID11583.value == "undefined" ||
        isNaN(this.data.Recodes.FID11583.value)
          ? 0
          : Number(this.data.Recodes.FID11583.value);

      this.setMulti11587(multi);
      return multi;
    },
    compClass11588: function () {
      return "grey";
    },
    multi11588: function () {
      let multi =
        typeof this.data.Recodes.FID11567.value == "undefined" ||
        isNaN(this.data.Recodes.FID11567.value)
          ? 0
          : Number(this.data.Recodes.FID11567.value);
      multi *=
        typeof this.data.Recodes.FID11584.value == "undefined" ||
        isNaN(this.data.Recodes.FID11584.value)
          ? 0
          : Number(this.data.Recodes.FID11584.value);

      this.setMulti11588(multi);
      return multi;
    },
    total11591: function () {
      let total = 0;
      total +=
        typeof this.data.Recodes.FID11593.value == "undefined" ||
        isNaN(this.data.Recodes.FID11593.value)
          ? 0
          : Number(this.data.Recodes.FID11593.value);
      total +=
        typeof this.data.Recodes.FID11594.value == "undefined" ||
        isNaN(this.data.Recodes.FID11594.value)
          ? 0
          : Number(this.data.Recodes.FID11594.value);
      this.setTotal11591(total);
      return total;
    },
    compClass11692: function () {
      return "grey";
    },
    compClass11593: function () {
      return "grey";
    },
    multi11593: function () {
      let multi =
        typeof this.data.Recodes.FID11692.value == "undefined" ||
        isNaN(this.data.Recodes.FID11692.value)
          ? 0
          : Number(this.data.Recodes.FID11692.value);
      multi *=
        typeof this.data.Recodes.FID11589.value == "undefined" ||
        isNaN(this.data.Recodes.FID11589.value)
          ? 0
          : Number(this.data.Recodes.FID11589.value);

      this.setMulti11593(multi);
      return multi;
    },
    compClass11594: function () {
      return "grey";
    },
    multi11594: function () {
      let multi =
        typeof this.data.Recodes.FID11569.value == "undefined" ||
        isNaN(this.data.Recodes.FID11569.value)
          ? 0
          : Number(this.data.Recodes.FID11569.value);
      multi *=
        typeof this.data.Recodes.FID11590.value == "undefined" ||
        isNaN(this.data.Recodes.FID11590.value)
          ? 0
          : Number(this.data.Recodes.FID11590.value);

      this.setMulti11594(multi);
      return multi;
    },
    total11521: function () {
      let total = 0;
      total +=
        typeof this.data.Recodes.FID11508.value == "undefined" ||
        isNaN(this.data.Recodes.FID11508.value)
          ? 0
          : Number(this.data.Recodes.FID11508.value);
      total +=
        typeof this.data.Recodes.FID11546.value == "undefined" ||
        isNaN(this.data.Recodes.FID11546.value)
          ? 0
          : Number(this.data.Recodes.FID11546.value);
      total +=
        typeof this.data.Recodes.FID11550.value == "undefined" ||
        isNaN(this.data.Recodes.FID11550.value)
          ? 0
          : Number(this.data.Recodes.FID11550.value);
      total +=
        typeof this.data.Recodes.FID11554.value == "undefined" ||
        isNaN(this.data.Recodes.FID11554.value)
          ? 0
          : Number(this.data.Recodes.FID11554.value);
      total +=
        typeof this.data.Recodes.FID11514.value == "undefined" ||
        isNaN(this.data.Recodes.FID11514.value)
          ? 0
          : Number(this.data.Recodes.FID11514.value);
      total +=
        typeof this.data.Recodes.FID11558.value == "undefined" ||
        isNaN(this.data.Recodes.FID11558.value)
          ? 0
          : Number(this.data.Recodes.FID11558.value);
      total +=
        typeof this.data.Recodes.FID11573.value == "undefined" ||
        isNaN(this.data.Recodes.FID11573.value)
          ? 0
          : Number(this.data.Recodes.FID11573.value);
      total +=
        typeof this.data.Recodes.FID11579.value == "undefined" ||
        isNaN(this.data.Recodes.FID11579.value)
          ? 0
          : Number(this.data.Recodes.FID11579.value);
      total +=
        typeof this.data.Recodes.FID11585.value == "undefined" ||
        isNaN(this.data.Recodes.FID11585.value)
          ? 0
          : Number(this.data.Recodes.FID11585.value);
      total +=
        typeof this.data.Recodes.FID11591.value == "undefined" ||
        isNaN(this.data.Recodes.FID11591.value)
          ? 0
          : Number(this.data.Recodes.FID11591.value);
      this.setTotal11521(total);
      return total;
    },
    compClass11504: function () {
      return "amber";
    },
    multi11525: function () {
      let multi =
        typeof this.data.Recodes.FID11522.value == "undefined" ||
        isNaN(this.data.Recodes.FID11522.value)
          ? 0
          : Number(this.data.Recodes.FID11522.value);
      multi *=
        typeof this.data.Recodes.FID11524.value == "undefined" ||
        isNaN(this.data.Recodes.FID11524.value)
          ? 0
          : Number(this.data.Recodes.FID11524.value);

      this.setMulti11525(multi);
      return multi;
    },
    multi11602: function () {
      let multi =
        typeof this.data.Recodes.FID11599.value == "undefined" ||
        isNaN(this.data.Recodes.FID11599.value)
          ? 0
          : Number(this.data.Recodes.FID11599.value);
      multi *=
        typeof this.data.Recodes.FID11601.value == "undefined" ||
        isNaN(this.data.Recodes.FID11601.value)
          ? 0
          : Number(this.data.Recodes.FID11601.value);

      this.setMulti11602(multi);
      return multi;
    },
    multi11606: function () {
      let multi =
        typeof this.data.Recodes.FID11603.value == "undefined" ||
        isNaN(this.data.Recodes.FID11603.value)
          ? 0
          : Number(this.data.Recodes.FID11603.value);
      multi *=
        typeof this.data.Recodes.FID11605.value == "undefined" ||
        isNaN(this.data.Recodes.FID11605.value)
          ? 0
          : Number(this.data.Recodes.FID11605.value);

      this.setMulti11606(multi);
      return multi;
    },
    multi11610: function () {
      let multi =
        typeof this.data.Recodes.FID11607.value == "undefined" ||
        isNaN(this.data.Recodes.FID11607.value)
          ? 0
          : Number(this.data.Recodes.FID11607.value);
      multi *=
        typeof this.data.Recodes.FID11609.value == "undefined" ||
        isNaN(this.data.Recodes.FID11609.value)
          ? 0
          : Number(this.data.Recodes.FID11609.value);

      this.setMulti11610(multi);
      return multi;
    },
    total11531: function () {
      let total = 0;
      total +=
        typeof this.data.Recodes.FID11534.value == "undefined" ||
        isNaN(this.data.Recodes.FID11534.value)
          ? 0
          : Number(this.data.Recodes.FID11534.value);
      total +=
        typeof this.data.Recodes.FID11535.value == "undefined" ||
        isNaN(this.data.Recodes.FID11535.value)
          ? 0
          : Number(this.data.Recodes.FID11535.value);
      this.setTotal11531(total);
      return total;
    },
    compClass11693: function () {
      return "grey";
    },
    compClass11534: function () {
      return "grey";
    },
    multi11534: function () {
      let multi =
        typeof this.data.Recodes.FID11693.value == "undefined" ||
        isNaN(this.data.Recodes.FID11693.value)
          ? 0
          : Number(this.data.Recodes.FID11693.value);
      multi *=
        typeof this.data.Recodes.FID11529.value == "undefined" ||
        isNaN(this.data.Recodes.FID11529.value)
          ? 0
          : Number(this.data.Recodes.FID11529.value);

      this.setMulti11534(multi);
      return multi;
    },
    compClass11535: function () {
      return "grey";
    },
    multi11535: function () {
      let multi =
        typeof this.data.Recodes.FID11528.value == "undefined" ||
        isNaN(this.data.Recodes.FID11528.value)
          ? 0
          : Number(this.data.Recodes.FID11528.value);
      multi *=
        typeof this.data.Recodes.FID11530.value == "undefined" ||
        isNaN(this.data.Recodes.FID11530.value)
          ? 0
          : Number(this.data.Recodes.FID11530.value);

      this.setMulti11535(multi);
      return multi;
    },
    total11615: function () {
      let total = 0;
      total +=
        typeof this.data.Recodes.FID11633.value == "undefined" ||
        isNaN(this.data.Recodes.FID11633.value)
          ? 0
          : Number(this.data.Recodes.FID11633.value);
      total +=
        typeof this.data.Recodes.FID11643.value == "undefined" ||
        isNaN(this.data.Recodes.FID11643.value)
          ? 0
          : Number(this.data.Recodes.FID11643.value);
      this.setTotal11615(total);
      return total;
    },
    compClass11694: function () {
      return "grey";
    },
    compClass11633: function () {
      return "grey";
    },
    multi11633: function () {
      let multi =
        typeof this.data.Recodes.FID11694.value == "undefined" ||
        isNaN(this.data.Recodes.FID11694.value)
          ? 0
          : Number(this.data.Recodes.FID11694.value);
      multi *=
        typeof this.data.Recodes.FID11613.value == "undefined" ||
        isNaN(this.data.Recodes.FID11613.value)
          ? 0
          : Number(this.data.Recodes.FID11613.value);

      this.setMulti11633(multi);
      return multi;
    },
    compClass11643: function () {
      return "grey";
    },
    multi11643: function () {
      let multi =
        typeof this.data.Recodes.FID11612.value == "undefined" ||
        isNaN(this.data.Recodes.FID11612.value)
          ? 0
          : Number(this.data.Recodes.FID11612.value);
      multi *=
        typeof this.data.Recodes.FID11614.value == "undefined" ||
        isNaN(this.data.Recodes.FID11614.value)
          ? 0
          : Number(this.data.Recodes.FID11614.value);

      this.setMulti11643(multi);
      return multi;
    },
    total11619: function () {
      let total = 0;
      total +=
        typeof this.data.Recodes.FID11635.value == "undefined" ||
        isNaN(this.data.Recodes.FID11635.value)
          ? 0
          : Number(this.data.Recodes.FID11635.value);
      total +=
        typeof this.data.Recodes.FID11645.value == "undefined" ||
        isNaN(this.data.Recodes.FID11645.value)
          ? 0
          : Number(this.data.Recodes.FID11645.value);
      this.setTotal11619(total);
      return total;
    },
    compClass11695: function () {
      return "grey";
    },
    compClass11635: function () {
      return "grey";
    },
    multi11635: function () {
      let multi =
        typeof this.data.Recodes.FID11695.value == "undefined" ||
        isNaN(this.data.Recodes.FID11695.value)
          ? 0
          : Number(this.data.Recodes.FID11695.value);
      multi *=
        typeof this.data.Recodes.FID11617.value == "undefined" ||
        isNaN(this.data.Recodes.FID11617.value)
          ? 0
          : Number(this.data.Recodes.FID11617.value);

      this.setMulti11635(multi);
      return multi;
    },
    compClass11645: function () {
      return "grey";
    },
    multi11645: function () {
      let multi =
        typeof this.data.Recodes.FID11616.value == "undefined" ||
        isNaN(this.data.Recodes.FID11616.value)
          ? 0
          : Number(this.data.Recodes.FID11616.value);
      multi *=
        typeof this.data.Recodes.FID11618.value == "undefined" ||
        isNaN(this.data.Recodes.FID11618.value)
          ? 0
          : Number(this.data.Recodes.FID11618.value);

      this.setMulti11645(multi);
      return multi;
    },
    total11623: function () {
      let total = 0;
      total +=
        typeof this.data.Recodes.FID11637.value == "undefined" ||
        isNaN(this.data.Recodes.FID11637.value)
          ? 0
          : Number(this.data.Recodes.FID11637.value);
      total +=
        typeof this.data.Recodes.FID11647.value == "undefined" ||
        isNaN(this.data.Recodes.FID11647.value)
          ? 0
          : Number(this.data.Recodes.FID11647.value);
      this.setTotal11623(total);
      return total;
    },
    compClass11696: function () {
      return "grey";
    },
    compClass11637: function () {
      return "grey";
    },
    multi11637: function () {
      let multi =
        typeof this.data.Recodes.FID11696.value == "undefined" ||
        isNaN(this.data.Recodes.FID11696.value)
          ? 0
          : Number(this.data.Recodes.FID11696.value);
      multi *=
        typeof this.data.Recodes.FID11621.value == "undefined" ||
        isNaN(this.data.Recodes.FID11621.value)
          ? 0
          : Number(this.data.Recodes.FID11621.value);

      this.setMulti11637(multi);
      return multi;
    },
    compClass11647: function () {
      return "grey";
    },
    multi11647: function () {
      let multi =
        typeof this.data.Recodes.FID11620.value == "undefined" ||
        isNaN(this.data.Recodes.FID11620.value)
          ? 0
          : Number(this.data.Recodes.FID11620.value);
      multi *=
        typeof this.data.Recodes.FID11622.value == "undefined" ||
        isNaN(this.data.Recodes.FID11622.value)
          ? 0
          : Number(this.data.Recodes.FID11622.value);

      this.setMulti11647(multi);
      return multi;
    },
    total11627: function () {
      let total = 0;
      total +=
        typeof this.data.Recodes.FID11639.value == "undefined" ||
        isNaN(this.data.Recodes.FID11639.value)
          ? 0
          : Number(this.data.Recodes.FID11639.value);
      total +=
        typeof this.data.Recodes.FID11649.value == "undefined" ||
        isNaN(this.data.Recodes.FID11649.value)
          ? 0
          : Number(this.data.Recodes.FID11649.value);
      this.setTotal11627(total);
      return total;
    },
    compClass11697: function () {
      return "grey";
    },
    compClass11639: function () {
      return "grey";
    },
    multi11639: function () {
      let multi =
        typeof this.data.Recodes.FID11697.value == "undefined" ||
        isNaN(this.data.Recodes.FID11697.value)
          ? 0
          : Number(this.data.Recodes.FID11697.value);
      multi *=
        typeof this.data.Recodes.FID11625.value == "undefined" ||
        isNaN(this.data.Recodes.FID11625.value)
          ? 0
          : Number(this.data.Recodes.FID11625.value);

      this.setMulti11639(multi);
      return multi;
    },
    compClass11649: function () {
      return "grey";
    },
    multi11649: function () {
      let multi =
        typeof this.data.Recodes.FID11624.value == "undefined" ||
        isNaN(this.data.Recodes.FID11624.value)
          ? 0
          : Number(this.data.Recodes.FID11624.value);
      multi *=
        typeof this.data.Recodes.FID11626.value == "undefined" ||
        isNaN(this.data.Recodes.FID11626.value)
          ? 0
          : Number(this.data.Recodes.FID11626.value);

      this.setMulti11649(multi);
      return multi;
    },
    total11631: function () {
      let total = 0;
      total +=
        typeof this.data.Recodes.FID11641.value == "undefined" ||
        isNaN(this.data.Recodes.FID11641.value)
          ? 0
          : Number(this.data.Recodes.FID11641.value);
      total +=
        typeof this.data.Recodes.FID11651.value == "undefined" ||
        isNaN(this.data.Recodes.FID11651.value)
          ? 0
          : Number(this.data.Recodes.FID11651.value);
      this.setTotal11631(total);
      return total;
    },
    compClass11698: function () {
      return "grey";
    },
    compClass11641: function () {
      return "grey";
    },
    multi11641: function () {
      let multi =
        typeof this.data.Recodes.FID11698.value == "undefined" ||
        isNaN(this.data.Recodes.FID11698.value)
          ? 0
          : Number(this.data.Recodes.FID11698.value);
      multi *=
        typeof this.data.Recodes.FID11629.value == "undefined" ||
        isNaN(this.data.Recodes.FID11629.value)
          ? 0
          : Number(this.data.Recodes.FID11629.value);

      this.setMulti11641(multi);
      return multi;
    },
    compClass11651: function () {
      return "grey";
    },
    multi11651: function () {
      let multi =
        typeof this.data.Recodes.FID11628.value == "undefined" ||
        isNaN(this.data.Recodes.FID11628.value)
          ? 0
          : Number(this.data.Recodes.FID11628.value);
      multi *=
        typeof this.data.Recodes.FID11630.value == "undefined" ||
        isNaN(this.data.Recodes.FID11630.value)
          ? 0
          : Number(this.data.Recodes.FID11630.value);

      this.setMulti11651(multi);
      return multi;
    },
    total11538: function () {
      let total = 0;
      total +=
        typeof this.data.Recodes.FID11525.value == "undefined" ||
        isNaN(this.data.Recodes.FID11525.value)
          ? 0
          : Number(this.data.Recodes.FID11525.value);
      total +=
        typeof this.data.Recodes.FID11602.value == "undefined" ||
        isNaN(this.data.Recodes.FID11602.value)
          ? 0
          : Number(this.data.Recodes.FID11602.value);
      total +=
        typeof this.data.Recodes.FID11606.value == "undefined" ||
        isNaN(this.data.Recodes.FID11606.value)
          ? 0
          : Number(this.data.Recodes.FID11606.value);
      total +=
        typeof this.data.Recodes.FID11610.value == "undefined" ||
        isNaN(this.data.Recodes.FID11610.value)
          ? 0
          : Number(this.data.Recodes.FID11610.value);
      total +=
        typeof this.data.Recodes.FID11531.value == "undefined" ||
        isNaN(this.data.Recodes.FID11531.value)
          ? 0
          : Number(this.data.Recodes.FID11531.value);
      total +=
        typeof this.data.Recodes.FID11615.value == "undefined" ||
        isNaN(this.data.Recodes.FID11615.value)
          ? 0
          : Number(this.data.Recodes.FID11615.value);
      total +=
        typeof this.data.Recodes.FID11619.value == "undefined" ||
        isNaN(this.data.Recodes.FID11619.value)
          ? 0
          : Number(this.data.Recodes.FID11619.value);
      total +=
        typeof this.data.Recodes.FID11623.value == "undefined" ||
        isNaN(this.data.Recodes.FID11623.value)
          ? 0
          : Number(this.data.Recodes.FID11623.value);
      total +=
        typeof this.data.Recodes.FID11627.value == "undefined" ||
        isNaN(this.data.Recodes.FID11627.value)
          ? 0
          : Number(this.data.Recodes.FID11627.value);
      total +=
        typeof this.data.Recodes.FID11631.value == "undefined" ||
        isNaN(this.data.Recodes.FID11631.value)
          ? 0
          : Number(this.data.Recodes.FID11631.value);
      this.setTotal11538(total);
      return total;
    },
    compClass11539: function () {
      return "amber";
    },
    watchFromID11541: function () {
      let value = this.data.Recodes.FID11483.value;
      this.setWatchFromID11541(value);
      return value;
    },
    watchFromID11657: function () {
      let value = this.data.Recodes.FID11521.value;
      this.setWatchFromID11657(value);
      return value;
    },
    watchFromID11659: function () {
      let value = this.data.Recodes.FID11538.value;
      this.setWatchFromID11659(value);
      return value;
    },
    compClass11676: function () {
      return "grey";
    },
    total11676: function () {
      let total = 0;
      total +=
        typeof this.data.Recodes.FID11541.value == "undefined" ||
        isNaN(this.data.Recodes.FID11541.value)
          ? 0
          : Number(this.data.Recodes.FID11541.value);
      total +=
        typeof this.data.Recodes.FID11657.value == "undefined" ||
        isNaN(this.data.Recodes.FID11657.value)
          ? 0
          : Number(this.data.Recodes.FID11657.value);
      total +=
        typeof this.data.Recodes.FID11659.value == "undefined" ||
        isNaN(this.data.Recodes.FID11659.value)
          ? 0
          : Number(this.data.Recodes.FID11659.value);
      total +=
        typeof this.data.Recodes.FID11661.value == "undefined" ||
        isNaN(this.data.Recodes.FID11661.value)
          ? 0
          : Number(this.data.Recodes.FID11661.value);
      total +=
        typeof this.data.Recodes.FID11663.value == "undefined" ||
        isNaN(this.data.Recodes.FID11663.value)
          ? 0
          : Number(this.data.Recodes.FID11663.value);
      total +=
        typeof this.data.Recodes.FID11665.value == "undefined" ||
        isNaN(this.data.Recodes.FID11665.value)
          ? 0
          : Number(this.data.Recodes.FID11665.value);
      this.setTotal11676(total);
      return total;
    },
    subtraction11671: function () {
      let total =
        typeof this.data.Recodes.FID11676.value == "undefined" ||
        isNaN(this.data.Recodes.FID11676.value)
          ? 0
          : Number(this.data.Recodes.FID11676.value);
      total -=
        typeof this.data.Recodes.FID11668.value == "undefined" ||
        isNaN(this.data.Recodes.FID11668.value)
          ? 0
          : Number(this.data.Recodes.FID11668.value);

      this.total11671(total);
      return total;
    },
    showTemplate11677() {
      if (this.$store.state.role == 1 || this.$store.state.role == 2) {
        return true;
      }
      if (this.data.Recodes.FID11678.value == "") {
        return true;
      }
      return false;
    },
    allDisabled11679() {
      // 管理者とグループ管理者は入力可能
      if (this.$store.state.role == 1 || this.$store.state.role == 2) {
        return false;
      }
      return true;
    },
    userRole() {
      let role = "一般";
      if (this.$store.state.role == 1) {
        role = "管理者";
      } else if (this.$store.state.role == 2) {
        role = "グループ管理者";
      }
      return role;
    },
  },
  watch: {
    $route() {
      this.getInit();
    },
    data: {
      handler: async function (data) {
        if (
          data.Recodes.FID11346.value != "" &&
          (
            data.Recodes.FID11678.value == "" ||
            data.Recodes.FID11678.value == null
          )
        ) {
          this.data.Recodes.FID11678.disabled = false;
        } else {
          this.data.Recodes.FID11678.disabled = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    async getInit() {
      try {
        //初期処理
        this.isLoading = true;
        //
        if (this.$route.query.copyFlag) {
          this.reportCopy();
        } else if (this.$route.params.report_id != null) {
          this.data.ReportID = Number(this.$route.params.report_id);
          //データ取得
          let data = {
            PageID: this.data.PageID,
            ReportID: Number(this.data.ReportID),
          };
          const result = await api.ReportDetailV2(data);
          this.RecodeCopy(this.data, result.Recodes);
          //初期処理
        }
        //データ取得処理

        //init処理
        this.initValues11340();
        this.initValues11842();
        this.initValues12223();
        this.initValues11341();
        this.initValues11342();
        this.initValues11343();
        this.initValues13154();
        this.initValues11869();
        this.initValues13378();
        this.initCheck11345();
        this.initFID11349();

        //読み込み終了
        this.isLoading = false;
      } catch (e) {
        console.log(e);
        alert("読み込みに失敗しました");
        this.back();
      }
    },
    RecodeCopy(data, response) {
      for (const [FID] of Object.entries(data.Recodes)) {
        if (typeof response[FID] != "undefined") {
          data.Recodes[FID].value = response[FID].value;
        }
        if (
          typeof response[FID] != "undefined" &&
          typeof response[FID].subReports != "undefined" &&
          response[FID].subReports != null
        ) {
          data.Recodes[FID].subReports = response[FID].subReports;
        }
      }
    },
    calc11931sp(target, Recodes) {
      let total = moneyStatusCheck(target, Recodes);
      this.$set(target, "value", String(total));
      return total;
    },
    async onBtn11345() {
      //処理を記載
      const date = this.getDateTime();
      this.data.Recodes.FID11345.value = date; //tag:日付+時間
      this.data.Recodes.FID11345.disabled = true; //1回だけ
    },
    getDateTime() {
      let date = new Date();
      let years = date.getFullYear().toString();
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let days = date.getDate().toString().padStart(2, "0");
      let hours = date.getHours().toString().padStart(2, "0");
      let minutes = date.getMinutes().toString().padStart(2, "0");
      let seconds = date.getSeconds().toString().padStart(2, "0");
      //
      let value =
        years +
        "-" +
        month +
        "-" +
        days +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;
      if (value == " ") {
        return "";
      }
      return value;
    },
    setWatchFromID11346(value) {
      this.data.Recodes.FID11346.value = value;
    },
    setMulti11413(value) {
      this.data.Recodes.FID11413.value = value;
    },
    setMulti11418(value) {
      this.data.Recodes.FID11418.value = value;
    },
    setMulti11422(value) {
      this.data.Recodes.FID11422.value = value;
    },
    setMulti11428(value) {
      this.data.Recodes.FID11428.value = value;
    },
    setTotal11432(value) {
      this.data.Recodes.FID11432.value = value;
    },
    setMulti11434(value) {
      this.data.Recodes.FID11434.value = value;
    },
    setMulti11435(value) {
      this.data.Recodes.FID11435.value = value;
    },
    setTotal11440(value) {
      this.data.Recodes.FID11440.value = value;
    },
    setMulti11443(value) {
      this.data.Recodes.FID11443.value = value;
    },
    setMulti11444(value) {
      this.data.Recodes.FID11444.value = value;
    },
    setTotal11448(value) {
      this.data.Recodes.FID11448.value = value;
    },
    setMulti11451(value) {
      this.data.Recodes.FID11451.value = value;
    },
    setMulti11452(value) {
      this.data.Recodes.FID11452.value = value;
    },
    setTotal11456(value) {
      this.data.Recodes.FID11456.value = value;
    },
    setMulti11459(value) {
      this.data.Recodes.FID11459.value = value;
    },
    setMulti11460(value) {
      this.data.Recodes.FID11460.value = value;
    },
    setTotal11464(value) {
      this.data.Recodes.FID11464.value = value;
    },
    setMulti11467(value) {
      this.data.Recodes.FID11467.value = value;
    },
    setMulti11468(value) {
      this.data.Recodes.FID11468.value = value;
    },
    setTotal11472(value) {
      this.data.Recodes.FID11472.value = value;
    },
    setMulti11475(value) {
      this.data.Recodes.FID11475.value = value;
    },
    setMulti11476(value) {
      this.data.Recodes.FID11476.value = value;
    },
    setTotal11483(value) {
      this.data.Recodes.FID11483.value = value;
    },
    setMulti11508(value) {
      this.data.Recodes.FID11508.value = value;
    },
    setMulti11546(value) {
      this.data.Recodes.FID11546.value = value;
    },
    setMulti11550(value) {
      this.data.Recodes.FID11550.value = value;
    },
    setMulti11554(value) {
      this.data.Recodes.FID11554.value = value;
    },
    setTotal11514(value) {
      this.data.Recodes.FID11514.value = value;
    },
    setMulti11517(value) {
      this.data.Recodes.FID11517.value = value;
    },
    setMulti11518(value) {
      this.data.Recodes.FID11518.value = value;
    },
    setTotal11558(value) {
      this.data.Recodes.FID11558.value = value;
    },
    setMulti11561(value) {
      this.data.Recodes.FID11561.value = value;
    },
    setMulti11562(value) {
      this.data.Recodes.FID11562.value = value;
    },
    setTotal11573(value) {
      this.data.Recodes.FID11573.value = value;
    },
    setMulti11575(value) {
      this.data.Recodes.FID11575.value = value;
    },
    setMulti11576(value) {
      this.data.Recodes.FID11576.value = value;
    },
    setTotal11579(value) {
      this.data.Recodes.FID11579.value = value;
    },
    setMulti11581(value) {
      this.data.Recodes.FID11581.value = value;
    },
    setMulti11582(value) {
      this.data.Recodes.FID11582.value = value;
    },
    setTotal11585(value) {
      this.data.Recodes.FID11585.value = value;
    },
    setMulti11587(value) {
      this.data.Recodes.FID11587.value = value;
    },
    setMulti11588(value) {
      this.data.Recodes.FID11588.value = value;
    },
    setTotal11591(value) {
      this.data.Recodes.FID11591.value = value;
    },
    setMulti11593(value) {
      this.data.Recodes.FID11593.value = value;
    },
    setMulti11594(value) {
      this.data.Recodes.FID11594.value = value;
    },
    setTotal11521(value) {
      this.data.Recodes.FID11521.value = value;
    },
    setMulti11525(value) {
      this.data.Recodes.FID11525.value = value;
    },
    setMulti11602(value) {
      this.data.Recodes.FID11602.value = value;
    },
    setMulti11606(value) {
      this.data.Recodes.FID11606.value = value;
    },
    setMulti11610(value) {
      this.data.Recodes.FID11610.value = value;
    },
    setTotal11531(value) {
      this.data.Recodes.FID11531.value = value;
    },
    setMulti11534(value) {
      this.data.Recodes.FID11534.value = value;
    },
    setMulti11535(value) {
      this.data.Recodes.FID11535.value = value;
    },
    setTotal11615(value) {
      this.data.Recodes.FID11615.value = value;
    },
    setMulti11633(value) {
      this.data.Recodes.FID11633.value = value;
    },
    setMulti11643(value) {
      this.data.Recodes.FID11643.value = value;
    },
    setTotal11619(value) {
      this.data.Recodes.FID11619.value = value;
    },
    setMulti11635(value) {
      this.data.Recodes.FID11635.value = value;
    },
    setMulti11645(value) {
      this.data.Recodes.FID11645.value = value;
    },
    setTotal11623(value) {
      this.data.Recodes.FID11623.value = value;
    },
    setMulti11637(value) {
      this.data.Recodes.FID11637.value = value;
    },
    setMulti11647(value) {
      this.data.Recodes.FID11647.value = value;
    },
    setTotal11627(value) {
      this.data.Recodes.FID11627.value = value;
    },
    setMulti11639(value) {
      this.data.Recodes.FID11639.value = value;
    },
    setMulti11649(value) {
      this.data.Recodes.FID11649.value = value;
    },
    setTotal11631(value) {
      this.data.Recodes.FID11631.value = value;
    },
    setMulti11641(value) {
      this.data.Recodes.FID11641.value = value;
    },
    setMulti11651(value) {
      this.data.Recodes.FID11651.value = value;
    },
    setTotal11538(value) {
      this.data.Recodes.FID11538.value = value;
    },
    setWatchFromID11541(value) {
      this.data.Recodes.FID11541.value = value;
    },
    setWatchFromID11657(value) {
      this.data.Recodes.FID11657.value = value;
    },
    setWatchFromID11659(value) {
      this.data.Recodes.FID11659.value = value;
    },
    setTotal11676(value) {
      this.data.Recodes.FID11676.value = value;
    },
    total11671(value) {
      this.data.Recodes.FID11671.value = value;
    },
    calc11986sp(target, Recodes) {
      let total = cashdifference(target, Recodes);
      this.$set(target, "value", String(total));
      return total;
    },
    async save11677() {
      this.data.Recodes.FID11677.disabled = true;
      this.data.Recodes.FID11677.value = this.getDateTime();
      //(N96)送信処理を追加

      if (!(await this.checkValidation())) {
        this.data.Recodes.FID11677.disabled = false;
        return;
      }
      this.escapeValidation = true;
      await updatePatrolreport(this.data); //特殊関数の呼び出し
      await this.send();
      this.data.Recodes.FID11677.disabled = false;
    },
    async onBtn11678() {
      let check = await this.checkValidation();
      if (!check) {
        return;
      }
      //処理を記載
      const date = this.getDateTime();
      this.data.Recodes.FID11678.value = date; //tag:日付+時間
      await this.save11677(); //送信処理
    },
    async onBtn12268() {
      //処理を記載
      this.goSelectorID("FID11340");
    },
    async onBtn13153() {
      //処理を記載
      this.goSelectorID("FID11678");
    },
    goSelectorID(id) {
      //要素が無いと移動できないため
      this.isLoading = false;
      this.$nextTick(() => {
        const elements = document.querySelectorAll("*");
        // idがある要素のidを配列に格納
        for (let i = 0; i < elements.length; i++) {
          if (elements[i].id == id) {
            this.$vuetify.goTo(elements[i], { offset: 100 });
            break;
          }
        }
      });
    },
    initValues11340() {
      if (
        this.data.Recodes.FID11340.value == "" &&
        typeof localStorage.getItem("kakanaiValueFID11758") != "undefined" &&
        localStorage.getItem("kakanaiValueFID11758") != null
      ) {
        this.data.Recodes.FID11340.value = localStorage.getItem(
          "kakanaiValueFID11758"
        );
        localStorage.removeItem("kakanaiValueFID11758");
      }
    },
    initValues11842() {
      if (
        this.data.Recodes.FID11842.value == "" &&
        typeof localStorage.getItem("kakanaiValueFID11841") != "undefined" &&
        localStorage.getItem("kakanaiValueFID11841") != null
      ) {
        this.data.Recodes.FID11842.value = localStorage.getItem(
          "kakanaiValueFID11841"
        );
        localStorage.removeItem("kakanaiValueFID11841");
      }
    },
    initValues12223() {
      if (
        this.data.Recodes.FID12223.value == "" &&
        typeof localStorage.getItem("kakanaiValueFID12222") != "undefined" &&
        localStorage.getItem("kakanaiValueFID12222") != null
      ) {
        this.data.Recodes.FID12223.value = localStorage.getItem(
          "kakanaiValueFID12222"
        );
        localStorage.removeItem("kakanaiValueFID12222");
      }
    },
    initValues11341() {
      if (
        this.data.Recodes.FID11341.value == "" &&
        typeof localStorage.getItem("kakanaiValueFID11763") != "undefined" &&
        localStorage.getItem("kakanaiValueFID11763") != null
      ) {
        this.data.Recodes.FID11341.value = localStorage.getItem(
          "kakanaiValueFID11763"
        );
        localStorage.removeItem("kakanaiValueFID11763");
      }
    },
    initValues11342() {
      if (
        this.data.Recodes.FID11342.value == "" &&
        typeof localStorage.getItem("kakanaiValueFID11767") != "undefined" &&
        localStorage.getItem("kakanaiValueFID11767") != null
      ) {
        this.data.Recodes.FID11342.value = localStorage.getItem(
          "kakanaiValueFID11767"
        );
        localStorage.removeItem("kakanaiValueFID11767");
      }
    },
    initValues11343() {
      if (
        this.data.Recodes.FID11343.value == "" &&
        typeof localStorage.getItem("kakanaiValueFID11768") != "undefined" &&
        localStorage.getItem("kakanaiValueFID11768") != null
      ) {
        this.data.Recodes.FID11343.value = localStorage.getItem(
          "kakanaiValueFID11768"
        );
        localStorage.removeItem("kakanaiValueFID11768");
      }
    },
    initValues13154() {
      if (
        this.data.Recodes.FID13154.value == "" &&
        typeof localStorage.getItem("kakanaiValueFID11770") != "undefined" &&
        localStorage.getItem("kakanaiValueFID11770") != null
      ) {
        this.data.Recodes.FID13154.value = localStorage.getItem(
          "kakanaiValueFID11770"
        );
        localStorage.removeItem("kakanaiValueFID11770");
      }
    },
    initValues11869() {
      if (
        this.data.Recodes.FID11869.value == "" &&
        typeof localStorage.getItem("kakanaiValueFID11844") != "undefined" &&
        localStorage.getItem("kakanaiValueFID11844") != null
      ) {
        this.data.Recodes.FID11869.value = localStorage.getItem(
          "kakanaiValueFID11844"
        );
        localStorage.removeItem("kakanaiValueFID11844");
      }
    },
    initValues13378() {
      if (
        this.data.Recodes.FID13378.value == "" &&
        typeof localStorage.getItem("kakanaiValueFID13155") != "undefined" &&
        localStorage.getItem("kakanaiValueFID13155") != null
      ) {
        this.data.Recodes.FID13378.value = localStorage.getItem(
          "kakanaiValueFID13155"
        );
        localStorage.removeItem("kakanaiValueFID13155");
      }
    },
    initCheck11345() {
      if (typeof this.data.Recodes.FID11345 == "undefined") {
        this.data.Recodes.FID11345 = { value: "", formType: 16 };
      }
      if (this.data.Recodes.FID11345.value != "") {
        this.data.Recodes.FID11345.disabled = true; //1回だけ
      }
    },
    initFID11349() {
      if (
        this.data.Recodes.FID11349.value == "" &&
        this.$store.state.params &&
        this.$store.state.params.FID13317
      ) {
        this.data.Recodes.FID11349.value =
          this.$store.state.params.FID13317.value;
      }
    },

    checkValidation: async function () {
      let form = this.$refs.form;
      let check = form.validate();
      if (!check) {
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return check;
      }

      return check;
    },
    async send() {
      const result = this.escapeValidation
        ? true
        : await this.checkValidation();

      if (result) {
        this.isLoading = true;

        const save = await api.SendReport(this.data);
        if (save == false || save.result == "Fail") {
          alert("登録失敗[Error0830-1]");
          this.isLoading = false;
          return;
        }
        console.log("SendPage1655");

        if (this.sendCopyFlag) {
          this.sendCopy();
          return;
        }
        alert("登録完了");
        if (!this.noBack) {
          this.back();
        } else {
          this.$set(this.$route.params, "report_id", save.reportID);
          this.$set(this.data, "ReportID", save.reportID);
          await this.getInit();
          this.isLoading = false;
          this.noBack = false;
        }
      }
    },
    back() {
      store.commit("clearreports");
      if (this.copyFlag) {
        history.go(this.backCount);
      } else {
        history.back();
      }
    },
    async del() {
      this.isLoading = true;
      await api.DelReport(this.data);
      alert("削除完了");
      this.back();
    },
    //金種内訳項目選択時の処理
    async handleInspectionItemChange(newValue) {
      if (newValue != null && newValue != "") {
        let check = await this.checkValidation();
        if (!check) {
          return;
        }
        //各金種内訳項目の表示制御
        this.isShowInspection1 = newValue == "0" || newValue == "1";
        this.isShowInspection2 = newValue == "0" || newValue == "2";
        this.isShowInspection3 = newValue == "0" || newValue == "3";
        this.isShowInspection4 = newValue == "0" || newValue == "4";
      }
    },
    // ナビメニューからの遷移用
    moveToPatrolReport() {
      this.$refs.patrolreportCreateSpecial.movePatrolReport();
    },
    moveToMoneyCheck() {
      this.$refs.patrolreportCreateSpecial.moveMoneyCheck();
    },
    moveToStockCheck() {
      this.$refs.patrolreportCreateSpecial.moveStockCheck();
    },
  },
};
</script>
<style></style>
